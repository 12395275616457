import axios from "axios";
import { API_URL } from "../configs/app";

export const fetchMenuCategories = async ({ queryKey }) => {
  let response = await axios.get(
    `${API_URL}/menu/category/${queryKey[1]}/${queryKey[2]}`
  );
  return response.data;
};
export const fetchMenuItems = async ({ queryKey }) => {
  const params = new URLSearchParams();
  if (queryKey[1]) params.append("hotelId", queryKey[1]);
  if (queryKey[2]) params.append("type", queryKey[2]);
  if (queryKey[3]) params.append("menuCategoryId", queryKey[3]);
  if (queryKey[4]) {
    const parsedValue = parseInt(queryKey[4]);
    if (isNaN(parsedValue)) {
        params.append("name", queryKey[4]);
    } else {
        params.append("amount", queryKey[4]);
    }
  }
  let response = await axios.get(`${API_URL}/menu?${params.toString()}`);
  return response.data;
};
export const createMenuCategory = async (data) => {
  let response = await axios.post(`${API_URL}/menu/category`, data);
  return response.data;
};

export const createMenuItem = async (data) => {
  let response = await axios.post(`${API_URL}/menu`, data);
  return response.data;
};
export const updateMenuItem = async (data) => {
  let response = await axios.patch(`${API_URL}/menu`, data);
  return response.data;
};
export const deleteMenuItem = async (data) => {
  let response = await axios.put(`${API_URL}/menu`, data);
  return response.data;
};
