import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuType: "food",
  menuCategory:null,
  menuSearch:null
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenuType : (state, action) =>{
      state.menuType = action.payload;
    },
    setMenuCategory : (state, action) =>{
      state.menuCategory = action.payload;
    },
    setMenuSearch : (state, action) => {
      state.menuSearch = action.payload;
    },
    clearSearch : (state) => {
      state.menuCategory = null
      state.menuSearch = null
    }
  },
});

export const {
    setMenuType, setMenuCategory, setMenuSearch, clearSearch,
} = menuSlice.actions;
export default menuSlice.reducer;
