import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./screens/Home";
import ProtectedRoute from "./middlewares/ProtectedRoute";
import Dashboard from "./screens/Admin/Dashboard";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "./store/store";
import Hotels from "./screens/Hotels";
import SingleHotel from "./screens/SingleHotel";
import ScrollToTop from "./components/ScrollToTop";
import SingleRoom from "./screens/SingleRoom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Checkout from "./screens/Checkout";
import OrderConfimation from "./screens/OrderConfimation";
import OrderDetails from "./screens/OrderDetails";
import Menu from "./screens/Menu";
import Discount15 from "./screens/Discount15";
import Login from "./screens/Login";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logout from "./screens/Admin/Logout";
import Bookings from "./screens/Admin/Bookings";
import Feedbacks from "./screens/Admin/Feedbacks";
import Blog from "./screens/Blog";
import BlogPost from "./screens/BlogPost";
import ValPromo from "./screens/ValPromo";
import Sojourner from "./screens/Sojourner";
import FoodMenu from "./screens/Admin/FoodMenu";
import ConfirmOrder from "./screens/ConfirmOrder";


let persistor = persistStore(store);
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  // firebase()
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <ToastContainer />
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/menu" element={<Menu />} />
              <Route path="/hotel" element={<Hotels />} />
              <Route path="/hotel/:slug" element={<SingleHotel />} />
              <Route path="/room/:slug" element={<SingleRoom />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/order-confirmation" element={<OrderConfimation />} />
              <Route path="/confirm-order" element={<ConfirmOrder />} />
              <Route path="/order-details" element={<OrderDetails />} />
              <Route path="/discount15" element={<Discount15 />} />
              <Route path="/promotions" element={<Discount15 />} />
              <Route path="/val-special-offer" element={<ValPromo />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/post/:title/:id" element={<BlogPost />} />
              <Route path="/sojourner" element={<Sojourner/>} />



              <Route path="/auth/login" element={<Login />} />
              <Route path="/auth/logout" element={<Logout />} />
              <Route path="/admin/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute> }/>
              <Route path="/admin/bookings" element={<ProtectedRoute><Bookings /></ProtectedRoute> }/>
              <Route path="/admin/feedbacks" element={<ProtectedRoute><Feedbacks /></ProtectedRoute> }/>
              <Route path="/admin/food-drink-menu" element={<ProtectedRoute><FoodMenu /></ProtectedRoute> }/>
              <Route path="/*" element={<Home />} />
            </Routes>
          </Router>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
