import React from 'react'
const image1 = require("../../assets/images/background/promo_background.webp")
const image2 = require("../../assets/images/background/castle1.webp")
const image3 = require("../../assets/images/background/place1.webp")
const Services = () => {
  return (
    <section className="services section-padding2">
  <div className="container">
    <div className="row">
      <div className="col-md-6 p-0 animate-box" data-animate-effect="fadeInLeft">
        <div className="img left">
          <a href="/hotel/the-sojourner-by-genesis"><img src={image1} alt /></a>
        </div>
      </div>
      <div className="col-md-6 p-0 bg-cream valign animate-box" data-animate-effect="fadeInRight">
        <div className="content">
          <div className="cont text-left">
            <div className="info">
              <h6>Special</h6>
            </div>
            <h4>Offers</h4>
            <p>By booking directly through our website for our special Valentine's offer, you unlock exclusive discounts not found on other platforms. Be the first to receive updates on upcoming promotions and unique offers, guaranteeing that you won't miss out on extraordinary deals. Take advantage of substantial savings on your romantic getaway.</p>
            <p><a href='/hotel/the-sojourner-by-genesis'><button className='btn btn-danger btn-lg'>Book Now!</button></a></p>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-6 bg-cream p-0 order2 valign animate-box" data-animate-effect="fadeInLeft">
        <div className="content">
          <div className="cont text-left">
            <div className="info">
              <h6>Best Rate </h6>
            </div>
            <h4>Guaranteed</h4>
            <p>When you book directly with us, you're guaranteed the best available rate. Enjoy exclusive discounts and special promotions that enhance your stay. Beyond savings, benefit from personalized customer service, ensuring a worry-free experience. Your satisfaction is our priority, making your stay truly remarkable. Book now and experience the advantages firsthand.</p>
            <p><a href='/hotel/the-sojourner-by-genesis'><button className='btn btn-danger btn-lg'>Book Now!</button></a></p>

          </div>
        </div>
      </div>
      <div className="col-md-6 p-0 order1 animate-box" data-animate-effect="fadeInRight">
        <div className="img">
          <a href="/hotel/the-sojourner-by-genesis"><img src={image2} alt /></a>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-6 p-0 animate-box" data-animate-effect="fadeInLeft">
        <div className="img left">
          <a href="/hotel/the-sojourner-by-genesis"><img src={image3} alt /></a>
        </div>
      </div>
      <div className="col-md-6 p-0 bg-cream valign animate-box" data-animate-effect="fadeInRight">
        <div className="content">
          <div className="cont text-left">
            <div className="info">
              <h6>Instant</h6>
            </div>
            <h4>Confirmation</h4>
            <p> Receive instant booking confirmation, so you can plan the rest of your trip with confidence, knowing your accommodations are secured.</p>
            <p><a href='/hotel/the-sojourner-by-genesis'><button className='btn btn-danger btn-lg'>Book Now!</button></a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Services
