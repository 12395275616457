import { combineReducers, configureStore } from '@reduxjs/toolkit'
import appSlice from './slices/appSlice'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import adminSlice from './slices/adminSlice'
import menuSlice from './slices/menuSlice'

const rootReducer = combineReducers({
  app: appSlice,
  admin: adminSlice,
  menu: menuSlice
})
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer )

export const store = configureStore({
  reducer: persistedReducer,
})
