import React from 'react'
import { Link } from 'react-router-dom'
import { FaBook, FaCalendar, FaCalendarAlt, FaHamburger, FaHotel, FaSignOutAlt, FaTachometerAlt, FaUtensilSpoon, FaUtensils } from "react-icons/fa";
import { useSelector } from 'react-redux';


const logo = require("../../assets/images/logos/genesis_hotels.png")
const Sidebar = () => {
  let { adminDetails } = useSelector((state) => state.admin);
  const isSuperAdmin = !adminDetails?.hotelId

  return (
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
  {/* Sidebar - Brand */}
  <a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
    <div className="sidebar-brand-icon rotate-n-15">
      <i className="fas fa-laugh-wink" />
    </div>
    <div className="sidebar-brand-text mx-3">
        <img src={logo} className='img' style={{width:"70%"}}/>
    </div>
  </a>
  {/* Divider */}
  <hr className="sidebar-divider my-0" />
  {/* Nav Item - Dashboard */}
  <li className="nav-item">
    <Link className="nav-link" to="/admin/dashboard">
      <FaTachometerAlt className='mr-1'/>
      <span>Dashboard</span></Link>
  </li>
  {/* Divider */}
  <hr className="sidebar-divider" />
  {/* Heading */}
  <div className="sidebar-heading">
    Reservations
  </div>
  {/* Nav Item - Pages Collapse Menu */}
  <li className="nav-item">
    <Link className="nav-link" to="/admin/bookings">
      <FaCalendarAlt className='mr-1'/>
      <span>Bookings</span>
    </Link>
  </li>
  {/* Divider */}
  <hr className="sidebar-divider" />
  <li className="nav-item">
    <a href='https://www.genesisfeedback.store/auth/login' target='_blank' className="nav-link" 
    // to="/admin/feedbacks"
    >
      <FaBook className='mr-1'/>
      <span>Feedbacks</span></a>
  </li>
  {!isSuperAdmin && <li className="nav-item">
    <Link className="nav-link" to="/admin/food-drink-menu">
      <FaUtensils className='mr-1'/>
      <span>Food & Drinks Menu</span>
    </Link>
  </li>}
  <hr className="sidebar-divider" />
  {/* Heading */}
  <div className="sidebar-heading">
    Settings
  </div>
  {/* Nav Item - Charts */}
  {/* <li className="nav-item">
    <Link className="nav-link" to="/admin/hotels">
      <FaHotel className='mr-1'/>
      <span>Hotels</span></Link>
  </li> */}
  {/* Nav Item - Tables */}
  <li className="nav-item">
    <Link className="nav-link" to="/auth/logout">
     <FaSignOutAlt className='mr-1'/>
      <span>Logout</span></Link>
  </li>
</ul>

  )
}

export default Sidebar
