import React, { useState } from 'react'
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa'
import { displayAmount, makeTitleCase } from '../../../utils/helpers'
import EditMenuModal from '../Modals/EditMenuModal'
import MenuItem from './MenuItem'

const MenuTable = ({loadingMenuItems, menuItems}) => {
  return (
    <>
    <div className="table-responsive">
    <table
      className="table table-striped table-hover table-bordered text-center"
      id="foodMenuTable"
    >
      <thead>
        <tr>
          <th scope="col">S/N</th>
          <th scope="col">Name</th>
          <th scope="col">Category</th>
          <th scope="col">Price</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        {loadingMenuItems && <tr><td colSpan={5}>Fetching menu items...</td></tr>}
        {menuItems && menuItems?.value?.length < 1 &&  <tr><td colSpan={5}>No data to display</td></tr>}
        {menuItems  && menuItems?.value?.length > 0 && menuItems?.value?.map((item, index)=>
          <MenuItem item={item} index={index}/>) }
      </tbody>
    </table>
  </div>
  </>)
}

export default MenuTable
