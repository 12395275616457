import React from "react";
import OwlCarousel from "react-owl-carousel";

function Testimonials() {
  return (
    <section className="testimonials">
      <div
        className="background bg-img bg-fixed section-padding pb-0"
        // data-background={require("../assets/images/background/promo_background.webp")}
        style={{background:`url(${require("../assets/images/background/promo_background.webp")})`, backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center"}}
        data-overlay-dark={3}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="testimonials-box">
                <div className="head-box">
                  <h6>Testimonials</h6>
                  <h4>What Client's Say?</h4>
                  <div className="line" />
                </div>
                <OwlCarousel  className="owl-theme" nav loop startPosition={0} dots={true}  autoplay autoplayTimeout={5000} items={1}>
       
                {/* <div className="owl-carousel owl-theme"> */}
                  <div className="item">
                    <span className="quote">
                      <img src="img/quot.png" alt />
                    </span>
                    <p>
                      I really like the hotel. I enjoyed my stay at the reventon hotel.
                    </p>
                    <div className="info">
                      <div className="author-img">
                        {" "}
                        {/* <img src="img/team/4.jpg" alt />{" "} */}
                      </div>
                      <div className="cont">
                        {" "}
                        <span>
                          <i className="star-rating" />
                          <i className="star-rating" />
                          <i className="star-rating" />
                          <i className="star-rating" />
                          <i className="star-rating" />
                        </span>
                        <h6>Adekunle James</h6> <span>Guest review</span>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <span className="quote">
                      <img src="img/quot.png" alt />
                    </span>
                    <p>
                     The ambience is superb, I love how customer-centric the staff were. 
                    </p>
                    <div className="info">
                      <div className="author-img">
                        {" "}
                        {/* <img src="img/team/1.jpg" alt />{" "} */}
                      </div>
                      <div className="cont">
                        {" "}
                        <span>
                          <i className="star-rating" />
                          <i className="star-rating" />
                          <i className="star-rating" />
                          <i className="star-rating" />
                          <i className="star-rating" />
                        </span>
                        <h6>Frank Barisi</h6> <span>Guest review</span>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <span className="quote">
                      <img src="img/quot.png" alt />
                    </span>
                    <p>
                     Amazing customer service. The staff were just superb!. 
                    </p>
                    <div className="info">
                      <div className="author-img">
                        {" "}
                        {/* <img src="img/team/5.jpg" alt />{" "} */}
                      </div>
                      <div className="cont">
                        {" "}
                        <span>
                          <i className="star-rating" />
                          <i className="star-rating" />
                          <i className="star-rating" />
                          <i className="star-rating" />
                          <i className="star-rating" />
                        </span>
                        <h6>Olivia Nzeogwu</h6> <span>Guest review</span>
                      </div>
                    </div>
                  </div>

                {/* </div> */}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
