import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Navbar from '../Navbar'
import "../../assets/css/style.css"
function WebsiteLayout({children}) {
  return (
    <>
        <Navbar/>
            {children}
        <Footer/>
    </>
  )
}

export default WebsiteLayout