import React from 'react'
import OwlCarousel from 'react-owl-carousel';

const Header = () => {
  return (
    <header className="header slider">
  {/* <div className="owl-carousel owl-theme"> */}
  <OwlCarousel  className="owl-carousel owl-theme" nav loop startPosition={0} dots={false} center autoplay autoplayTimeout={5000} items={1}>
    <div className="text-center item bg-img" data-overlay-dark={2} 
    // data-background="../../assets/images/banners/discount15_2.jpg" 
    style={{
    background:`url(${require("../../assets/images/banners/discount15_2.jpg")})`, 
    backgroundRepeat:"no-repeat", 
    backgroundSize:"cover", 
    backgroundPosition:"center"
    }} 
    />
    <div className="text-center item bg-img" data-overlay-dark={2} 
    // data-background="../../assets/images/banners/discount15_3.jpg" 
    style={{
        background:`url(${require("../../assets/images/banners/discount15_3.jpg")})`, 
        backgroundRepeat:"no-repeat", 
        backgroundSize:"cover", 
        backgroundPosition:"center"
        }} 
    />
    <div className="text-center item bg-img" data-overlay-dark={2} 
    // data-background="../../assets/images/banners/discount15_1.jpg"
    style={{
        background:`url(${require("../../assets/images/banners/discount15_1.jpg")})`, 
        backgroundRepeat:"no-repeat", 
        backgroundSize:"cover", 
        backgroundPosition:"center"
        }} 
     />
    </OwlCarousel>
  {/* </div> */}
  {/* arrow down */}
  <div className="arrow bounce text-center">
    <a href="#" data-scroll-nav={1} className> <i className="ti-arrow-down" /> </a>
  </div>
</header>

  )
}

export default Header
