import React from "react";
import Header from "../components/Hotel/Header";
import WebsiteLayout from "../components/layouts/WebsiteLayout";
import Navbar from "../components/Navbar";
import { Country, State, City } from "country-state-city";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { fetchSingleHotelBySlug, fetchSingleRoom } from "../api/hotels.api";
import { displayAmount, makeTitleCase, showAlert } from "../utils/helpers";
import { initiateBooking } from "../api/bookings.api";
import { initiatePayment } from "../api/payment.api";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
const image = require("../assets/images/banners/sojourn3.webp");
const paystackImage = require("../assets/images/payments/paystack.png");
const squadImage = require("../assets/images/payments/squad.png");

const Checkout = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let slug = searchParams.get("hotel");
  let roomSlug = searchParams.get("room");
  let arrival = searchParams.get("arrival");
  let departure = searchParams.get("departure");
  let adult = searchParams.get("adult");
  let children = searchParams.get("children");

  const [hotel, setHotel] = useState(null);
  const [room, setRoom] = useState(null);
  let { currency } = useSelector(state=>state.app)


  let nights = moment(departure).diff(moment(arrival), "days");
  let price = currency === "USD" ? room?.promoPriceUsd?room?.promoPriceUsd:room?.priceUsd :  room?.promoPriceNgn?room?.promoPriceNgn:room?.priceNgn;
  let total = price * nights;

  const { isLoading: loadingHotel } = useQuery(
    ["hotels", slug],
    fetchSingleHotelBySlug,
    {
      onSuccess: (data) => {
        // console.log(data)
        setHotel(data.value);
      },
      onError: (err) => {
        console.log(err);
        return showAlert({
          title:"Error",
          msg:"An internal error occured, Try again.",
          icon:"error",
        })
      },
    }
  );

  const { isLoading: loadingRoom } = useQuery(
    ["room", roomSlug],
    fetchSingleRoom,
    {
      onSuccess: (data) => {
        //   console.log(data)
        setRoom(data.value);
      },
      onError: (err) => {
        console.log(err);
        return showAlert({
          title:"Error",
          msg:"An internal error occured, Try again.",
          icon:"error",
        })
      },
    }
  );

  let countries = Country.getAllCountries();
  const [currentCountry, setCurrentCountry] = useState({
    currency: "NGN",
    flag: "🇳🇬",
    isoCode: "NG",
    latitude: "10.00000000",
    longitude: "8.00000000",
    name: "Nigeria",
    phonecode: "234",
  });
  const [currentState, setCurrentState] = useState(null);
  const [currentStates, setCurrentStates] = useState(null);

  const [formdata, setFormdata] = useState({
    country: "NG",
    state: "RI",
    city: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    address: "",
    note: "",
    paymentChannel:currency === "USD"?"squad":"paystack"
  });

  const handleChange = (field, value) => {
    setFormdata((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleCountryChange = (e) => {
    e.preventDefault();
    let countryName = e.target.value;
    let countryObj = countries.find((x) => x.isoCode === countryName);
    // console.log("CHANGING COUNTRY", countryName)
    setFormdata((prev) => ({
      ...prev,
      country: countryName,
    }));
    setCurrentCountry(countryObj);
  };

  const handleStateChange = (e) => {
    e.preventDefault();
    let stateName = e.target.value;
    let stateObj = currentStates.find((x) => x.isoCode === stateName);
    setFormdata((prev) => ({
      ...prev,
      state: e.target.value,
    }));
    setCurrentState(stateObj);
  };

  useEffect(() => {
    if (currentCountry) {
      let countryStates = State.getStatesOfCountry(currentCountry.isoCode);
      setCurrentStates(countryStates);
      setFormdata((prev) => ({
        ...prev,
        state: countryStates[0]?.name,
      }));
    }
  }, [currentCountry]);

  const handleSubmit = (e) => {
    e.preventDefault();
if(!formdata.firstname || !formdata.lastname || !formdata.phone || !formdata.email){
  return showAlert({
    title:"Missing fields!",
    msg:"You have left out some compulsory fields",
    icon:"warning",
  })
}

    let payload = {
      customer: {
        firstName: formdata.firstname,
        lastName: formdata.lastname,
        phone: formdata.phone,
        email: formdata.email,
      },
      hotel,
      room,
      nights,
      state: formdata.state,
      country: formdata.country,
      currency,
      unitPrice: price,
      totalPrice: total,
      note: formdata.note,
      address: formdata.address,
      arrival, departure, adult, children,
      paymentChannel: formdata.paymentChannel
    };
    // console.log(payload);
    makeBooking(payload);
  };

  const {mutate:makePayment, isLoading: loadingPayment} = useMutation(initiatePayment,{
    onSuccess: data => {
        // console.log(data)
        if(!data?.status){ 
          console.log("error")
          showAlert({
            title:"Error",
            msg:"An internal error has occurred, Try again.",
            icon:"error"
          }); return;
        }
        if(data?.value?.paymentChannel === "paystack"){
          if(data?.value?.status){
            let redirectUrl = data?.value?.data?.authorization_url
             window.location = redirectUrl
         }
        }else if(data?.value?.paymentChannel === "squad"){
          if(data?.value?.success){
            let redirectUrl = data?.value?.data?.checkout_url
             window.location = redirectUrl
         }
        }
        
    },
    onError: err => {
        console.log(err)
        return showAlert({
          title:"Error",
          msg:"An internal error occured, Try again.",
          icon:"error",
        })
    }

  })

  const { mutate: makeBooking, isLoading:loadingBooking } = useMutation(initiateBooking, {
    onSuccess: (data) => {
      // console.log(data);
      if(!data.status){
        showAlert({
          title:"Error",
          msg:"An error has occurred, try again.",
          icon:"error"
        });
        return;
      }
      let {uid,  firstName, lastName = "", email, phone, room, hotel, nights, currency, totalPrice, paymentChannel } = data?.value
      //make request for payment 
      let payload = {
        name: firstName + " " + lastName,
        email, amount:totalPrice, ref:uid , currency, metadata :{
            hotel:hotel?.name,
            room: room?.name,
            nights, phone, 
            name:firstName + " " + lastName,
        },
        paymentChannel,
        hotel:hotel?.slug
      }
      // console.log(payload)
      makePayment(payload)

    },
    onError: (err) => {
      console.log(err)
      return showAlert({
        title:"Error",
        msg:"An internal error occurred, Try again.",
        icon:"error",
      })
    }
  });


  return (
    <WebsiteLayout>
      <Navbar />
      <Header
        image={image}
        title="Complete Booking"
        subtitle="Genesis Hotels"
      />

      {/* <section class="section-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
                
             
            </div>
          </div>
        </div>
      </section> */}
      <section className="contact section-padding">
        <div className="container">
          <div className="row mb-90">
            <div className="col-md-8 mb-30">
              <h3>Reserve Today and Enjoy Your Stay</h3>
              <form
                method="post"
                className="contact__form"
                onSubmit={handleSubmit}
              >
                {/* <div className="row">
            <div className="col-12">
              <div className="alert alert-success contact__msg" style={{display: 'none'}} role="alert"> Your message was sent successfully. </div>
            </div>
          </div> */}

                {/* form elements */}
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      name="name"
                      type="text"
                      placeholder="First Name *"
                      value={formdata.firstname}
                      onChange={(e) =>
                        handleChange("firstname", e.target.value)
                      }
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <input
                      name="name"
                      type="text"
                      placeholder="Last Name *"
                      value={formdata.lastname}
                      onChange={(e) => handleChange("lastname", e.target.value)}
                      required
                    />
                  </div>

                  <div className="col-md-6 form-group">
                    <input
                      name="email"
                      type="email"
                      placeholder="Email *"
                      value={formdata.email}
                      onChange={(e) => handleChange("email", e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <input
                      name="phone"
                      type="text"
                      placeholder="Phone Number *"
                      value={formdata.phone}
                      onChange={(e) => handleChange("phone", e.target.value)}
                      required
                    />
                  </div>

                  <div className="col-md-6 form-group mb-5">
                    <label for="id-country">Country</label>
                    <select
                      id="id-country"
                      name="country"
                      className="form-control"
                      value={formdata.country}
                      onChange={handleCountryChange}
                    >
                      {countries.map((ctr) => (
                        <option
                          value={ctr.isoCode}
                        >{`${ctr.flag} ${ctr.name}`}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6 form-group mb-5">
                    <label for="id-state">State</label>
                    <select
                      id="id-state"
                      name="country"
                      className="form-control"
                      value={formdata.state}
                      onChange={handleStateChange}
                    >
                      {currentStates &&
                        currentStates.map((state) => (
                          <option value={state.isoCode}>{`${state.name}`}</option>
                        ))}
                    </select>
                  </div>

                  <div className="col-md-12 form-group ">
                    <input
                      name="address"
                      type="text"
                      placeholder="Enter your address"
                      value={formdata.address}
                      onChange={(e) => handleChange("address", e.target.value)}
                      required
                    />
                  </div>

                  <div className="col-md-12 form-group mb-5">
                    <label for="message">Additional Request</label>
                    <textarea
                      name="message"
                      id="message"
                      cols={30}
                      rows={4}
                      placeholder="If you have a special request for your reservation, please enter it here.We will do all we can to accommodate your special requests, however they cannot be guaranteed. "
                      defaultValue={""}
                      value={formdata.note}
                      onChange={(e) => handleChange("note", e.target.value)}
                    />
                  </div>

                  <div className="col-md-12 form-group mb-5">
                    <label for="payment-method">Select Payment Channel</label>
                    <div className="payment-methods d-md-flex align-items-center">
                      {currency !== "USD" && <div className="payment-method d-flex align-items-start me-md-5 mb-3 mb-md-0">
                        <input
                          id="credit-card"
                          name="payment-method"
                          type="radio"
                          value="paystack"
                          checked={formdata.paymentChannel === "paystack"}
                          onChange={(e) => handleChange("paymentChannel", e.target.value)}
                        />
                        <label for="credit-card" className="d-flex align-items-center mx-1"><img style={{maxWidth:formdata.paymentChannel === "paystack"?130:100, filter:formdata.paymentChannel === "paystack"?"":"grayscale(100%)"}} src={paystackImage} alt="Credit Card Icon" className="me-2" />
                        </label>
                      </div>}
                      <div className="payment-method  d-flex align-items-start">
                        <input
                          id="bank-transfer"
                          name="payment-method"
                          type="radio"
                          value="squad"
                          checked={formdata.paymentChannel === "squad"}
                          onChange={(e) => handleChange("paymentChannel", e.target.value)}
                        />
                        <label for="bank-transfer" className="d-flex align-items-center mx-1"> <img style={{maxWidth:formdata.paymentChannel === "squad"?100:80, filter:formdata.paymentChannel === "squad"?"":"grayscale(100%)"}} src={squadImage} alt="Bank Transfer Icon" className="me-2" />
                          
                        </label>
                      </div>
                    </div>
                  </div>

                
                  <div className="col-md-12">
                    <button className="butn-dark btn-block" type="submit" disabled={loadingBooking || loadingPayment}>
                    {
                      (loadingBooking || loadingPayment ) 
                      ? <Spinner animation="border" size="sm"/>
                      :<span>Make Payment {displayAmount(total, 2,  currency === "USD" ? "$" : "₦")}</span>
                    }
                      
                      
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-md-3 mb-60  offset-md-1">
              {/* {hotel && <h3>{makeTitleCase(hotel?.name)}</h3>} */}

              {hotel && (
                <div className="reservations mb-30">
                  <div className="icon">
                    <span className="flaticon-home" />
                  </div>
                  <div className="text">
                    <p>Hotel</p> <a href="#">{makeTitleCase(hotel?.name)}</a>
                  </div>
                </div>
              )}
              {room && (
                <div className="reservations mb-30">
                  <div className="icon">
                    <span className="flaticon-bed" />
                  </div>
                  <div className="text">
                    <p>Room</p> <a href="#">{makeTitleCase(room?.name)}</a>
                  </div>
                </div>
              )}
              {arrival && (
                <div className="reservations mb-30">
                  <div className="text ml-0">Arrival</div>
                  <div className="text">
                    <a href="#" className="font-18">
                      {moment(arrival).format("dddd MMM Do YYYY")}
                    </a>
                  </div>
                </div>
              )}
              {departure && (
                <div className="reservations mb-30">
                  <div className="text ml-0">Departure</div>
                  <div className="text">
                    <a href="#" className="font-18">
                      {moment(departure).format("dddd MMM Do YYYY")}
                    </a>
                  </div>
                </div>
              )}
              {arrival && departure && (
                <div className="reservations mb-30">
                  <div className="text ml-0">No of Nights</div>
                  <div className="text">
                    <a href="#" className="font-18">
                      {nights} {nights > 1?"Nights":"Night"}
                    </a>
                  </div>
                </div>
              )}

              {room && (
                <div className="reservations mb-30">
                  <div className="text ml-0">Amount/Night</div>
                  <div className="text">
                    <a href="#" className="font-18">
                      {displayAmount(price, 2, currency === "USD" ? "$":"₦")}
                    </a>
                  </div>
                </div>
              )}

              {room && (
                <div className="reservations mb-30">
                  <div className="text ml-0">Total Amount</div>
                  <div className="text">
                    <a href="#" className="font-18">
                      {displayAmount(total, 2,  currency === "USD" ? "$":"₦")}
                    </a>
                  </div>
                </div>
              )}
              <div style={{ display: "flex", alignItems: "center" }}>
                {adult && (
                  <div className="reservations mb-30">
                    <div className="text ml-0">Adults</div>
                    <div className="text">
                      <a href="#" className="font-18">
                        {adult}
                      </a>
                    </div>
                  </div>
                )}
                {children && (
                  <div className="reservations mb-30 ml-3">
                    <div className="text ml-0">Children</div>
                    <div className="text">
                      <a href="#" className="font-18">
                        {children}
                      </a>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="reservations">
                <div className="icon">
                  <span className="flaticon-location-pin" />
                </div>
                <div className="text">
                  <p>Address</p> 1616 Broadway NY, New York 10001
                  <br />
                  United States of America
                </div>
              </div> */}
              {/* <p>
                Hotel ut nisl quam nestibulum ac quam nec odio elementum sceisue
                the aucan ligula. Orci varius natoque penatibus et magnis dis
                parturient monte nascete ridiculus mus nellentesque habitant
                morbine.
              </p> */}
              {/* <div className="reservations mb-30">
                <div className="icon">
                  <span className="flaticon-call" />
                </div>
                <div className="text">
                  <p>Reservation</p> <a href="tel:855-100-4444">855 100 4444</a>
                </div>
              </div>
            
              */}
            </div>
          </div>
        </div>
      </section>
    </WebsiteLayout>
  );
};

export default Checkout;
