import React from 'react'

const DescriptionSection = () => {
  return (
    <section className="rooms-page section-padding" data-scroll-index={1}>
  <div className="container">
    <div className="row">
      <div className="col-md-12"> <span><i className="star-rating" /><i className="star-rating" /><i className="star-rating" /><i className="star-rating" /><i className="star-rating" /></span>
        <div className="section-subtitle">16A OBA AKINJOBI STREET GRA IKEJA LAGOS </div>
        <div className="section-title">The Sojourner By Genesis</div>
        <p className="mb-30">
        Book now for a fantastic staycation at the Sojourner! Experience luxury and comfort, where personalized service meets unmatched hospitality. Don't miss this exclusive offer to make your stay memorable. 🥰
        </p>
      </div>
      <div className="col-md-12">
        <div className="reservations">
          <div className="text">
            <p>Book now!</p> <a href="/hotel/the-sojourner-by-genesis"><button className='btn btn-danger'>Start Your Booking</button></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default DescriptionSection
