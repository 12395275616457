import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchSingleHotel, fetchSingleHotelById, fetchSingleRoom } from "../api/hotels.api";
import Header from "../components/Hotel/Header";
import RoomHeader from "../components/Hotel/RoomHeader";
import WebsiteLayout from "../components/layouts/WebsiteLayout";
import Navbar from "../components/Navbar";
import SingleRoomTile from "../components/SingleRoomTile";
import hotels from "../hotels.json";
import { displayAmount, makeTitleCase, showAlert } from "../utils/helpers";
import BookingModal from "../components/Modals/BookingModal";

function SingleRoom() {
  const {slug } = useParams();
  const [room, setRoom] = useState(null)
  const [hotel, setHotel] = useState(null)
  const [price, setPrice] = useState(0)
  const [promoPrice, setPromoPrice] = useState(0)
  let params = null
  // const hotel = hotels.find((hx) => hx.id === hid);

  // const room = hotel.rooms.find(rx=>rx.id == rid)

  const [showBookingModal, setShowBookingModal] = useState(false)
  const handleBookNow  = (e) => {
    e.preventDefault();
    if(params){
      window.location = `/checkout?hotel=${hotel.slug}&room=${room.slug}&arrival=${params.arrival}&departure=${params.departure}&adult=${params.adult}&children=${params.children}`
    }
else{
setShowBookingModal(true)
}
  }


  const {  isLoading:loadingRoom } = useQuery(['room',slug ], fetchSingleRoom, {
    onSuccess: data => {
      // console.log(data)
      setRoom(data.value)
    },
      onError: err => {
        console.log(err)
        return showAlert({
          title:"Error",
          msg:"An internal error occured, Try again.",
          icon:"error",
        })
      }
  })

  const { refetch:getHotel} = useQuery(['hotel', room?.hotelId],fetchSingleHotelById ,{
    enabled:false,
    onSuccess: data => {
      // console.log(data)
      setHotel(data.value)
    },
      onError: err => {
        console.log(err)
        return showAlert({
          title:"Error",
          msg:"An internal error occured, Try again.",
          icon:"error",
        })
      }
  })

  let { currency } = useSelector(state=>state.app)
  useEffect(() => {
   if(room){
    getHotel();
    let price = currency === "USD" ? displayAmount(room.priceUsd,0,"$") :  displayAmount(room.priceNgn)
    let promoPrice = currency === "USD" ? displayAmount(room.promoPriceUsd,0,"$") :  displayAmount(room.promoPriceNgn)
    setPrice(price);
    setPromoPrice(promoPrice)
   }
  }, [room])
  
  const handleCheckout = (data) => {
    window.location = `/checkout?hotel=${hotel.slug}&room=${room.slug}&arrival=${data.arrival}&departure=${data.departure}&adult=${data.adult}&children=${data.children}`
  }


  return (<>
    <WebsiteLayout>
       <Navbar />
     {/* <Header image={room.images[1]?room.images[1]:room.images[0]} title={room.name} subtitle="" /> */}
     {!room && loadingRoom && 
     <div style={{textAlign:"center"}}>
        <Spinner animation="border" size="lg"/>
     </div>
     }
      {room && 
      <>
      <RoomHeader images={room.images}/> 
<section className="rooms-page section-padding" data-scroll-index={1}>
  <div className="container">
    {/* project content */}
    <div className="row">
      <div className="col-md-12"> 
        {hotel && <span>
        {Array(hotel?.rating).fill().map(x=><i key={Math.random()*9999999} className="star-rating" />)}
        </span>}
        {hotel && <div className="section-subtitle">{makeTitleCase(hotel?.name)}</div>}
        <div className="section-title">{makeTitleCase(room?.name)}</div>
        <div className="section-subtitle mb-3" style={{fontWeight:"bold", color:"red"}}>
          
        {
         (promoPrice === "₦0" || promoPrice === "$0")? <>{price}/Night</>:<> <del className="text-muted">{price}</del>  {promoPrice}/Night</>
        }

       
          
          </div>
      </div>
      <div className="col-md-8">
        <p className="mb-30">
          {room?.description}
          </p>
        <div className="row">
          <div className="col-md-6">
            <h6>Check-in</h6>
            <ul className="list-unstyled page-list mb-30">
              <li>
                <div className="page-list-icon"> <span className="ti-check" /> </div>
                <div className="page-list-text">
                  <p>Check-in from 2:00 PM - anytime</p>
                </div>
              </li>
              <li>
                <div className="page-list-icon"> <span className="ti-check" /> </div>
                <div className="page-list-text">
                  <p>Early check-in subject to availability</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <h6>Check-out</h6>
            <ul className="list-unstyled page-list mb-30">
              <li>
                <div className="page-list-icon"> <span className="ti-check" /> </div>
                <div className="page-list-text">
                  <p>Check-out before noon</p>
                </div>
              </li>
              <li>
                <div className="page-list-icon"> <span className="ti-check" /> </div>
                <div className="page-list-text">
                  <p>Express check-out</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-12">
            <h6>Special instructions</h6>
            <ul>
              <li>We do not allow cheques at the desk</li>
              <li>Refund is made within 48 working hours.</li>
              <li>Reservation can only be guaranteed by making payment.</li>
              <li>The above rate is subject to change without prior notice</li>
              <li>Food and Beverages are not allowed into the hotel from outside by the guest.</li>
              <li>Guaranteed booking not cancelled before 12 noon on arrival date attracts 100% no show charge.</li>
              <li>Late check-out attracts 50% of room rate before 6:00 PM and full payment for the night after 6:00 PM</li>
              <li>Rate are inclusive of breakfast for one person only in our restaurant. It attracts charges for extra person or if ordered from the room.</li>
            </ul>
            
          </div>
          <div className="col-md-12">
            <h6>Pets</h6>
            <p>Pets are not allowed</p>
          </div>
          <div className="col-md-12">
            <h6>Children and extra beds</h6>
            <p> Children stay free when using existing bedding; children may not be eligible for complimentary breakfast. Rollaway/extra beds are available for a fee per day.</p>
          </div>
          <div className="col-md-12">
            <div className="mt-15 mb-30"> <a href="" className="butn-dark btn-danger text-white" onClick={handleBookNow}><span>Book Now</span></a> </div>
          </div>
        </div>
      </div>
      <div className="col-md-3 offset-md-1">
        <h6>Amenities</h6>
        <ul className="list-unstyled page-list mb-30">
          {room.amenities && room.amenities.length > 0 && room.amenities.map(
            item=><li key={`amen-${Math.random()*999999}`}>
            <div className="page-list-text">
              <p>{item}</p>
            </div>
          </li>
          )}
          
        </ul>
      </div>
    </div>
  </div>
</section>

      </>
      
      }


    </WebsiteLayout>
    {showBookingModal &&  <BookingModal show={showBookingModal} close={()=>setShowBookingModal(false)} checkout={handleCheckout} />}
  </>);
}

export default SingleRoom;
