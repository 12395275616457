import React, { useEffect, useState } from 'react'
import AdminLayout from '../../components/layouts/AdminLayout'
import { getFeedbacks, getReservations } from '../../api/admin.api'
import { toast } from 'react-toastify'
import { useQuery } from '@tanstack/react-query'
import { FaEye, FaStar } from 'react-icons/fa'
import { makeTitleCase, slugToTitle } from '../../utils/helpers'
import FeedbackModal from '../../components/Admin/FeedbackModal'

const Feedbacks = () => {
const [feedbackData, setFeedbackData] = useState(null)
const [showModal, setShowModal] = useState(false)
const [currentFeedback, setCurrentFeedback] = useState(null)

const handleModalClose = () => {
  setShowModal(false)
  setCurrentFeedback(null)
}
    const {isLoading:fetchingFeedbacks} = useQuery({
        queryKey: ['feedbacks'],
        queryFn: getFeedbacks,
        onSuccess: data => {
            if (!data.status && data.errors) {
                return toast.error(typeof data.errors[0].msg == "string"
                  ? data.errors[0].msg
                  : data.errors[0].msg.message)
              }
              setFeedbackData(data.value)
        }
    })

    useEffect(() => {
      if(currentFeedback){
       setShowModal(true)
      }
     }, [currentFeedback])
     

  return (
    <AdminLayout>
       <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Feedbacks</h1>
        </div>


        <div className="card shadow mb-4">
  <div className="card-header py-3">
    <h6 className="m-0 font-weight-bold text-primary">All Bookings</h6>
  </div>
  <div className="card-body">
    <div className="table-responsive">
      <table className="table table-bordered table-hover table-stripped text-center" id="" width="100%" cellSpacing={0}>
        <thead>
          <tr>
            <th>S/N</th>
            <th>Name</th>
            <th>Hotel</th>
            <th>Room</th>
            <th>Service</th>
            <th>Location</th>
            <th></th>
          </tr>
        </thead>
        <tfoot>
          <tr>
          <th>S/N</th>
            <th>Name</th>
            <th>Hotel</th>
            <th>Room</th>
            <th>Service</th>
            <th>Location</th>
            <th></th>
          </tr>
        </tfoot>
        <tbody>
            {
                fetchingFeedbacks && 
                <tr>
                    <td colSpan={7}>Loading...</td>
                </tr>
            }
          {!fetchingFeedbacks && feedbackData && feedbackData.map((feedback, index)=> 
          <tr key={feedback.uid}>
            <td>{index + 1}</td>
            <td>{makeTitleCase(feedback.name)}</td>
            <td>{makeTitleCase(slugToTitle(feedback.hotel))}</td>
            <td><StarRating rate={feedback.room}/> </td>
            <td><StarRating rate={feedback.service}/></td>
            <td><StarRating rate={feedback.location}/></td>
            <td>
                <button className='text-sm btn-sm btn-primary btn-outline' onClick={()=>setCurrentFeedback(feedback)}><FaEye/></button>
            </td>
          </tr>)
         }
        </tbody>
      </table>
    </div>
  </div>
</div>

{showModal && <FeedbackModal visible={showModal} feedback={currentFeedback} close={handleModalClose} />}
    </AdminLayout>
  )
}

export const StarRating = ({rate})=>{
  return Array(5).fill().map((item, index) =><FaStar className='mr-1'  style={{color: index < rate ? "gold" : "#ccc" }}
  data-toggle="tooltip" data-placement="top" 
  title={
    index === 0 ? "Very Poor" 
    : index === 1 ? "Poor" 
    : index === 2 ? "Okay" 
    : index === 3 ? "Good" 
    : "Excellent"}/>
  )
}

export default Feedbacks
