import React, { useEffect, useState } from 'react'
import AdminLayout from '../../components/layouts/AdminLayout'
import DashCard from '../../components/Admin/DashCard'
import { useQuery } from '@tanstack/react-query'
import { getAllHotels, getReportData } from '../../api/admin.api'
import { toast } from 'react-toastify'
import { displayAmount, makeTitleCase, thousand } from '../../utils/helpers'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import moment from 'moment'
import { Form, Spinner } from "react-bootstrap";
import { useSelector } from 'react-redux'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export const barchartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Hotel Earnings',
    },
  },
};
export const piechartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
    },
    title: {
      display: true,
      text: 'Hotel Bookings',
    },
  },
};

export const lineOptions = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
}

function Dashboard() {
  const { adminDetails } = useSelector(state=>state.admin)
  const isSuperAdmin = !adminDetails?.hotelId
  const [data, setData] = useState({})
  const [allHotels, setAllHotels] = useState([])
  const [earningsChartData, setEarningsChartData] = useState(null)
  const [bookingCountData, setBookingCountData] = useState(null)
  const [bookingsByDayData, setBookingsByDayData] = useState(null)
  const [bookingsByMonthData, setBookingsByMonthData] = useState(null)
  const [currentHotel, setCurrentHotel] = useState(isSuperAdmin?"all":adminDetails?.hotelId?._id)
  const [period, setPeriod] = useState("month");



  const {isLoading:fetchingHotels} = useQuery({
    queryKey:['getHotels'],
    queryFn: getAllHotels,
    enabled: isSuperAdmin,
    onError: err => {
      return toast.error("Error fetching hotels")
    },
    onSuccess: data => {
      if (!data.status && data.errors) {
        return toast.error(typeof data.errors[0].msg == "string"
          ? data.errors[0].msg
          : data.errors[0].msg.message)
      }
      setAllHotels(data.value)
    }
  }) 
  const { isLoading: fetchingReport } = useQuery({
    queryKey: ['getReport', currentHotel, period],
    queryFn: getReportData,
    onSuccess: data => {
      if (!data.status && data.errors) {
        return toast.error(typeof data.errors[0].msg == "string"
          ? data.errors[0].msg
          : data.errors[0].msg.message)
      }
      setData(data.value)
    }
  })

  const setChartData = () => {
    const hotelNamelabels = data.bookingByHotel.map(hotel => hotel.hotel)
    const bookingCounts = data.bookingByHotel.map(hotel => hotel.bookingCount)

    const sortedHotels = data.bookingByHotel.sort((a, b) => b.bookingEarning - a.bookingEarning)
    const earningLabels = sortedHotels.map(hotel => hotel.hotel)
    const earnings = sortedHotels.map(hotel => hotel.bookingEarning)

    const dailyBookingLabel = data.bookingByDay.map(booking => moment(booking._id).format('Do MMMM  YYYY'))
    const dailyBookingValue = data.bookingByDay.map(booking => booking.bookingCount)

    const monthlyBookingLabel = data.bookingByMonth.map(booking =>  moment(booking._id).format('MMMM YYYY'))
    const monthlyBookingValue = data.bookingByMonth.map(booking => booking.bookingCount)

    // console.log(dailyBookingLabel, monthlyBookingLabel)
    setEarningsChartData({
      labels: earningLabels,
      datasets: [
        {
          
          label: 'Earnings by Hotel',
          data: earnings,
          backgroundColor: 'rgba(185, 93, 160, 0.8)',
        },
      ],
    })
         
    setBookingsByDayData({
     
      labels: dailyBookingLabel,
      datasets: [
        {
          label: 'Daily Bookings',
          data: dailyBookingValue,
          borderColor: 'rgba(54, 162, 235, 0.8)',
        },
      ],
 
    })
    setBookingsByMonthData({
      labels: monthlyBookingLabel,
      datasets: [
        {
          label: 'Monthly Bookings',
          data: monthlyBookingValue,
          borderColor: 'rgba(153, 102, 255, 0.8)',
        },
      ],
    })
    setBookingCountData({
      labels: hotelNamelabels,
      datasets: [
        {
          label: 'Booking count by Hotel',
          data: bookingCounts,
          backgroundColor: 'rgba(255, 159, 64, 1)',
          // [
          //   'rgba(255, 99, 132, 0.2)',
          //   'rgba(54, 162, 235, 0.2)',
          //   'rgba(255, 206, 86, 0.2)',
          //   'rgba(75, 192, 192, 0.2)',
          //   'rgba(153, 102, 255, 0.2)',
          //   'rgba(255, 159, 64, 0.2)',
          // ],
          borderColor: 'rgba(255, 159, 64, 1)',
          // [
          //   'rgba(255, 99, 132, 1)',
          //   'rgba(54, 162, 235, 1)',
          //   'rgba(255, 206, 86, 1)',
          //   'rgba(75, 192, 192, 1)',
          //   'rgba(153, 102, 255, 1)',
          //   'rgba(255, 159, 64, 1)',
          // ],
          borderWidth: 1,
        },
      ],
    })
  }
  useEffect(() => {
    if (data.bookingByHotel) setChartData()
  }, [data])

  const handleHotelChange = (e) => {
    let hotel = e.target.value
    setCurrentHotel(hotel)

  }
  const handlePeriodChange = (e) => {
    if(e.target.value === "date"){
      // setShowRangePicker(true)
      return;
    }
    setData({})
    setPeriod(e.target.value);
  }

  return (
    <AdminLayout>
      <div className="container-fluid">

      <div className="d-sm-flex align-items-center justify-content-between mb-4" >
          <h1 className="h3 mb-0 text-gray-800">
            {isSuperAdmin?"Welcome":makeTitleCase(adminDetails?.hotelId?.name)}
          </h1>
         
          <div style={{ display: "flex" }}>
          {isSuperAdmin && <Form.Group className="mb-3 mr-3" controlId="formSelect">
                <Form.Select
                  aria-label="filter-duration"
                  onChange={handleHotelChange}
                  value={currentHotel}
                >
                  <option selected disabled value="">Filter</option>
                  <option value="all">All Hotels</option>
                  {
                    allHotels.map((item, index) => <option key={`hdhwr-${Math.floor(Math.random() * 9999999999)}`} value={item?._id}>{makeTitleCase(item?.name)}</option>)
                  }
                </Form.Select>
              </Form.Group>
            }
            <Form.Group className="mb-3" controlId="formSelect">
              <Form.Select
                aria-label="filter-duration"
                onChange={handlePeriodChange}
                value={period}
              >
                <option selected disabled value="">Filter</option>
                <option value="date">Custom Date</option>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="current_week">Current Week</option>
                <option value="last_week">Last Week</option>
                <option value="month">This Month</option>
                <option value="last_month">Last Month</option>
                <option value="all_time">All Time</option>
              </Form.Select>
            </Form.Group>

            {/* {showRangePicker && 
            <Form.Group className="mb-3 ml-3 mr-3" style={{ border: "1px solid #ccc", borderRadius: 5, backgroundColor: "#fff" }} controlId="formInput">
              <DateRangePicker onChange={handleDateRangeChange} />
            </Form.Group>} */}
          </div>

        </div>

      <div className="row">
        {/* <DashCard type="success" title="New Bookings" value={reportData?.value?.totalBookingCount || 0}/>    */}
        <DashCard type="primary" title="Total Bookings" value={thousand(data.totalBookingCount || 0)} />
        <DashCard type="warning" title="Total Revenue" value={displayAmount(data.totalRevenue || 0)} />
        <DashCard type="danger" title="Feedbacks" value={thousand(data.feedbackCount || 0)} />
      </div>

      <div className="row">
        <div className="col-xl-6 col-lg-6">
          {earningsChartData && <Bar options={barchartOptions} data={earningsChartData} />}
        </div>
        <div className="col-xl-6 col-lg-6">
          {bookingCountData && <Bar data={bookingCountData} />}
          {/* {bookingCountData && <Doughnut options={piechartOptions} data={bookingCountData} />} */}
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6 col-lg-6">
          {bookingsByDayData && <Line options={lineOptions} data={bookingsByDayData} />}
        </div>
        <div className="col-xl-6 col-lg-6">
          {bookingsByMonthData && <Line options={lineOptions}  data={bookingsByMonthData} />}
        </div>

      </div>

      </div>
    </AdminLayout>
  )
}

export default Dashboard