import React from 'react'
import WebsiteLayout from '../components/layouts/WebsiteLayout'
import Navbar from '../components/Navbar'
import Header from '../components/Hotel/Header'
const image = require("../assets/images/banners/sojourn3.webp")

const Menu = () => {
  return (
    <WebsiteLayout>     
        <Navbar/>
        <Header image={image} title="Our Menu" subtitle="Genesis Hotels" />
    </WebsiteLayout>
  )
}

export default Menu
