import React from 'react'
import WebsiteLayout from '../components/layouts/WebsiteLayout'
import Header from '../components/ValPromo/Header'
import DescriptionSection from '../components/ValPromo/DescriptionSection'
import Services from '../components/ValPromo/Services'
import Clients from '../components/Clients'

const ValPromo = () => {
  return (
    <WebsiteLayout>
        <Header/>
        <DescriptionSection/>
        <Services/>
        <Clients/>
    </WebsiteLayout>
  )
}

export default ValPromo
