import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { useSearchParams } from "react-router-dom";
import WebsiteLayout from "../components/layouts/WebsiteLayout";
import Navbar from "../components/Navbar";
import Header from "../components/Hotel/Header";
import { verifyPayment } from "../api/payment.api";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";
import { getSingleBooking, makeBookingOnOWS } from "../api/bookings.api";
const image = require("../assets/images/banners/sojourn3.webp");
const paymentSuccess = require("../assets/images/payment_success.gif");

const ConfirmOrder = () => {
  const [data, setData] = useState(null);
  const [bookingDetails, setBookingDetails] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams();
  let reference = searchParams.get("reference");

  const { isLoading: isFetching, refetch: runVerification } = useQuery(
    ["verifyTransaction", reference, "paystack"],
    verifyPayment,
    {
      enabled: false,
      onSuccess: (data) => {
        // console.log(data);
        if (!data.status) {
          return;
        }
        if (data?.value?.status) {
          setData(data?.value?.data);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const {isLoading:loadingBooking, refetch:fetchSingleBooking} = useQuery(["getBooking", reference], getSingleBooking , {
    enabled:false,
    onSuccess: data => {
    //   console.log(data);
      if(!data?.status) { return; }
      return setBookingDetails(data?.value)
    },
    onError: err => {
      console.log(err)
    }
  })


  useEffect(() => {
    if (reference && reference.length > 0) {
      fetchSingleBooking()
    }
  }, [reference]);

  useEffect(() => {
   if(bookingDetails){
    runVerification(reference);
   }
  }, [bookingDetails])
  

  return (
    <WebsiteLayout>
      <Navbar />
      <Header image={image} title="Confirmation" subtitle="Genesis Hotels" />
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12" style={{ textAlign: "center" }}>
              {isFetching && <Spinner animation="border" role="status" />}

              {!isFetching && data && (
                <>
                  <img
                    src={paymentSuccess}
                    style={{ width: 200, height: 200 }}
                  />
                  <h3>Payment Successful!</h3>
                  <p>
                    A receipt has been sent to your email with details of your reservation.
                  </p>
                  <div>
                    <a href="/" className="btn btn-default mx-3">
                      Go Home
                    </a>
                    {/* <a
                      href={`/order-details?ref=${data?.transaction_ref}`}
                      className="btn btn-success mx-3"
                    >
                      See Booking
                    </a> */}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </WebsiteLayout>
  );
};

export default ConfirmOrder;
