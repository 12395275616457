import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currency: "NGN"
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setStoreCurrency : (state, action) => {
      state.currency = action.payload;
    },
   
  },
});

export const {
  setStoreCurrency
} = appSlice.actions;
export default appSlice.reducer;
