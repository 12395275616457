import React, { useEffect, useState, useRef } from "react";
import { Modal, Row, Col, Badge, Spinner } from "react-bootstrap";
import { showAlert } from "../../utils/helpers";

const BookingModal = ({ show, close, checkout }) => {
  const arrival = useRef("arrival");
  const departure = useRef("departure");
  const [isLoading, setIsLoading] = useState(false)
  const [formdata, setFormdata] = useState({
    arrival: "",
    departure: "",
    adult: 1,
    children: 0,
  });
  useEffect(() => {
    arrival.current.min = new Date().toISOString().split("T")[0];
    departure.current.min = new Date().toISOString().split("T")[0];
  }, []);

  const handleChange = (field, value) => {
    if (field === "arrival") {
      departure.current.min = value;
    }
    setFormdata((prev) => ({
      ...prev,
      [field]: value,
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if(!formdata?.arrival || !formdata?.departure || !formdata?.adult) {
      return showAlert({
        title:"Incomplete Fields",
        msg:"Please check that you have entered the right information",
        icon:"error"
      })
    };
    setIsLoading(true)
    checkout(formdata)
}

  return (
    <Modal show={show} onHide={close} keyboard={false} centered size={"sm"}>
      <Modal.Header closeButton>
        <Modal.Title>Choose dates</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <form className="w-100 book_table_inner booking_form" onSubmit={handleSubmit}>
            <div className="input-append date mb-3">
              <label htmlFor="arrival">Arrival Date</label>
              <input
                defaultValue
                className="form-control"
                onChange={(e) => handleChange("arrival", e.target.value)}
                ref={arrival}
                placeholder="Arrival"
                type="date"
                name="arrival"
                id="arrival"
                min=""
                value={formdata.arrival}
              />
            </div>

            <div className="input-append date mb-3">
              <label htmlFor="departure">Departure Date</label>
              <input
                defaultValue
                className="form-control"
                onChange={(e) => handleChange("departure", e.target.value)}
                ref={departure}
                placeholder="Departure"
                type="date"
                name="departure"
                id="departure"
                min=""
                value={formdata.departure}
              />
            </div>
            <div
              className="mb-5"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="input-append date mb-3" style={{ width: "48%" }}>
                <label htmlFor="adults" style={{ display: "block" }}>
                  Adults
                </label>
                <select
                  className="form-control"
                  name="guestcount"
                  id="guestcount"
                  onChange={(e) => handleChange("adult", e.target.value)}
                  value={formdata.adult}
                  style={{ maxHeight: 35 }}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
              </div>

              <div className="input-append date mb-3" style={{ width: "48%" }}>
                <label htmlFor="adults" style={{ display: "block" }}>
                  Children
                </label>
                <select
                  className="form-control"
                  name="children"
                  id="children"
                  onChange={(e) => handleChange("children", e.target.value)}
                  value={formdata.children}
                  style={{ maxHeight: 35 }}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
              </div>
            </div>

            <button
              className="submitBtn book_now_btn"
              style={{ width: "100%" }}
              type="submit"
            >
             {isLoading
             ? <Spinner animation="border" size="sm"/>
             : "Continue"
            }
            </button>
          </form>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default BookingModal;
