import React from 'react'
import { useSelector } from 'react-redux'
import { makeTitleCase } from '../../utils/helpers'
const userImg = require("../../assets/images/undraw_profile.png")


const TopNav = () => {
    let { adminDetails } = useSelector(state=>state.admin)
    return (<>
        {adminDetails && <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            {/* Sidebar Toggle (Topbar) */}
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                <i className="fa fa-bars" />
            </button>
        
            <ul className="navbar-nav ml-auto">
                <div className="topbar-divider d-none d-sm-block" />
                {/* Nav Item - User Information */}
                <li className="nav-item dropdown no-arrow">
                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">{makeTitleCase(adminDetails.name)}</span>
                        <img className="img-profile rounded-circle" src={userImg} style={{width:35}} />
                </li>
            </ul>
        </nav>
}

   </> )
}

export default TopNav
