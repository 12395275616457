import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Navbar from '../Navbar'
import "../../assets/css/style.css"
import SojournerNavbar from '../SojournerNavbar'
function SojournerLayout({children}) {
  return (
    <>
        <SojournerNavbar/>
            {children}
        <Footer/>
    </>
  )
}

export default SojournerLayout