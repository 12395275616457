import React from "react";
import OwlCarousel from "react-owl-carousel";

function Services() {
  return (
    <section className="pricing section-padding bg-black">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="section-subtitle">
              <span>Best Prices</span>
            </div>
            <div className="section-title">
              <span>Extra Services</span>
            </div>
            <p className="color-2">
              We provide the best prices for your relaxing vacation at pocket friendly amounts. Take advantage of our hybrid meeting rooms to stay connected with your teams around the world. We guarantee a conducive environment for your business meetings
            </p>
            <p className="color-2">
            Book a stay with us and enjoy unlimited access to our facilities
            </p>
            <div className="reservations mb-30">
              <div className="icon">
                <span className="flaticon-call" />
              </div>
              <div className="text">
                <p className="color-2">For information</p>{" "}
                <a href="tel:08050499247">08050499247</a>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            {/* <div className="owl-carousel owl-theme"> */}
                <OwlCarousel  className="owl-theme" nav loop startPosition={0} dots={true} margin={30} autoplay autoplayTimeout={3000} items={2}>
       
              <div className="pricing-card">
                <img src={require("../assets/images/hall1.webp")} alt />
                <div className="desc">
                  <div className="name">Conference Halls</div>
                  {/* <div className="amount">
                  <span>from </span>₦100,000
                  </div> */}
                  <ul className="list-unstyled list">
                    <li>  <i className="ti-check" /> Hybrid halls </li>
                    <li> <i className="ti-check" /> Up to 400 attendees </li>
                    <li> <i className="ti-check" /> Convenient, Comfortable &amp; Exquisite  </li>
                  </ul>
                </div>
              </div>
              <div className="pricing-card">
                <img src={require("../assets/images/rest1.webp")} alt="" />
                <div className="desc">
                  <div className="name">Restaurant</div>
                
                  <ul className="list-unstyled list">
                    <li>  <i className="ti-check" /> Great Food, Delicious Meals </li>
                    <li>  <i className="ti-check" /> Fine Dinning </li>
                    <li> <i className="ti-check" />  Lovely Ambience  </li>
                  </ul>
                </div>
              </div>
              <div className="pricing-card">
                <img src={require("../assets/images/gym1.webp")} alt />
                <div className="desc">
                  <div className="name">Gymnasium</div>
                
                  <ul className="list-unstyled list">
                    <li>  <i className="ti-check" />  Well Equipped</li>
                    <li> <i className="ti-check" /> 24 hours Access  </li>
                    <li> <i className="ti-check" /> Competent Trainers   </li>
                  </ul>
                </div>
              </div>

              <div className="pricing-card">
              <img src={require("../assets/images/pool1.webp")} alt />
                <div className="desc">
                  <div className="name">Swimming Pool</div>
                
                  <ul className="list-unstyled list">
                    <li> <i className="ti-check" /> Spacious</li>
                    <li> <i className="ti-check" /> Well Suited  </li>
                    <li> <i className="ti-check" />Perfect Temperature </li>
                   
                  </ul>
                </div>
              </div>
              </OwlCarousel>
            {/* </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
