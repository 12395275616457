import axios from "axios"
import { API_URL, OWS_BASE_URL } from "../configs/app"


export const fetchHotels = async () => {
    let response = await axios.get(`${API_URL}/hotels`)
    return response.data
}
export const fetchSingleHotelBySlug = async ({queryKey}) => {
    let response = await axios.get(`${API_URL}/hotels/single-slug/${queryKey[1]}`)
    return response.data
}
export const fetchSingleHotelById = async ({queryKey}) => {
    let response = await axios.get(`${API_URL}/hotels/single-id/${queryKey[1]}`)
    return response.data
}

export const fetchRoomsByHotel = async ({queryKey}) => {
    let response = await axios.get(`${API_URL}/rooms/${queryKey[1]}`)
    return response.data
}
export const fetchAvailableRoomsByHotel = async ({queryKey}) => {
    const URL = `${OWS_BASE_URL}/availablity?arrival=${queryKey[1].arrival}&departure=${queryKey[1].departure}&property=${queryKey[1].property}&guestcount=${queryKey[1].adult}`
    let response = await axios.get(URL)
    console.log(response.data)
    return response.data
}

export const fetchSingleRoom = async ({queryKey}) => {
    let response = await axios.get(`${API_URL}/rooms/single/${queryKey[1]}`)
    return response.data
}