import React from 'react'
import { Link } from 'react-router-dom'
import { makeTitleCase } from '../../utils/helpers'
import moment from 'moment'

const BlogPostHeader = ({post}) => {
    console.log(post)
  return (
    <>{post &&
   <div className="banner-header section-padding valign bg-img bg-fixed" data-overlay-dark={4} data-background={post?.fields?.thumbnail?.fields?.file?.url} style={{backgroundImage:`url(${post?.fields?.thumbnail?.fields?.file?.url})`, backgroundOrigin:"center", backgroundPosition:"center"}}>
  <div className="container">
    <div className="row">
      <div className="col-md-12 text-left caption mt-90">
        <h5><Link to="/blog">Blog</Link> / {makeTitleCase(post?.fields?.title)}</h5>
        <h1>{makeTitleCase(post.fields.title)}</h1>
        <div className="post">
          <div className="author"> <img src={post?.fields?.author?.fields?.image?.fields?.file?.url} alt className="avatar" /> <span>{makeTitleCase(post?.fields?.author?.fields?.name)}</span> </div>
          <div className="date-comment"> <i className="ti-calendar" /> {moment(post.sys.createdAt).format("Do MMM  YYYY")} </div>
        </div>
      </div>
    </div>
  </div>
</div>}
</>
  )
}

export default BlogPostHeader
