import axios from "axios"
import { API_URL } from "../configs/app"

export const initiatePayment = async (data) => {
    let response = await axios.post(`${API_URL}/payments/initiate`, data)
    return response.data
}

export const verifyPayment = async ({queryKey}) => {
    let response = await axios.get(`${API_URL}/payments/verify/${queryKey[1]}/${queryKey[2]}`)
    return response.data
}