import React from "react";

function PromoVideo() {
  return (
    <section
      className="video-wrapper video section-padding bg-img bg-fixed"
      data-overlay-dark={3}
      // data-background={require("../assets/images/background/promo_background.webp")}
      style={{background:`url(${require("../assets/images/background/promo_background.webp")})`, backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center"}}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2 text-center">
            <span>
              <i className="star-rating" />
              <i className="star-rating" />
              <i className="star-rating" />
              <i className="star-rating" />
              <i className="star-rating" />
            </span>
            <div className="section-subtitle">
              <span>Genesis Hotels</span>
            </div>
            <div className="section-title">
              <span>Experience Us On Video</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="text-center col-md-12">
            <a className="vid" href="https://youtu.be/_iU9ylzCEQA">
              <div className="vid-butn">
                <span className="icon">
                  <i className="ti-control-play" />
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PromoVideo;