import React from 'react'
import { makeTitleCase, slugToTitle } from '../../utils/helpers'
import { StarRating } from '../../screens/Admin/Feedbacks'

const FeedbackModal = ({visible, close, feedback}) => {
  return (<>
    { feedback && <>
        <div className={`modal-backdrop fade ${visible?"show":""}`}> </div>
        <div className={`modal fade  ${visible?"show":""}`} style={{display: "block"}} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md" role="document">
            <div className="modal-content" style={{maxHeight: "calc(100vh - 100px)", overflowY: "auto"}}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">{makeTitleCase(slugToTitle(feedback.hotel))}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12 my-3">
                    <div className="card">
                      <div className="card-body">
                        {/* <h5 className="card-title">Customer Details</h5> */}
                        <p className="card-text">Room: <span className='bold'><StarRating rate={feedback.room}/></span></p>
                        <p className="card-text">Service: <span className='bold'><StarRating rate={feedback.service}/></span></p>
                        <p className="card-text">Location: <span className='bold'><StarRating rate={feedback.location}/></span></p>
                        <p className="card-text">Message: <span className='bold'>{feedback.message}</span></p>
                      </div>
                    </div>
                  </div>
                
                </div>
  
                <div className="row">
                  <div className="col-md-12">
                  <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">Customer Info</h5>
                        <p className="card-text">Name: <span className='bold'>{feedback.name}</span></p>
                        <p className="card-text">Email: <span className='bold'>{feedback.email}</span></p>
                        <p className="card-text">Phone: <span className='bold'>{feedback.phone}</span></p>
                      </div>
                    </div>
              </div>
              </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={close}>Close</button>
              </div>
            </div>
          </div>
        </div>
        </>}
       
        </>)
}

export default FeedbackModal
