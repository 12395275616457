import React from "react";

function Reservation() {
  return (
    <section className="testimonials">
      <div
        className="background bg-img bg-fixed section-padding pb-0"
        // data-background={require("../assets/images/background/promo_background.webp")}
        style={{background:`url(${require("../assets/images/background/promo_background.webp")})`, backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center"}}
        data-overlay-dark={2}
      >
        <div className="container">
          <div className="row">
            {/* Reservation */}
            <div className="col-md-5 mb-30 mt-30">
              <p>
                <i className="star-rating" />
                <i className="star-rating" />
                <i className="star-rating" />
                <i className="star-rating" />
                <i className="star-rating" />
              </p>
              <h5>
                Each of our guest rooms feature a private bath, WI-FI, cable
                television and includes full breakfast.
              </h5>
              <div className="reservations mb-30">
                <div className="icon color-1">
                  <span className="flaticon-call" />
                </div>
                <div className="text">
                  <p className="color-1">Reservation</p>{" "}
                  <a className="color-1" href="tel:08050499247">
                  08050499247
                  </a>
                </div>
              </div>
              <p>
                <i className="ti-check" />
                <small>Call us.</small>
              </p>
            </div>
            {/* Booking From */}
            {/* <div className="col-md-5 offset-md-2">
              <div className="booking-box">
                <div className="head-box">
                  <h6>Genesis Hotels</h6>
                  <h4>Hotel Booking Form</h4>
                </div>
                <div className="booking-inner clearfix">
                  <form
                    className="form1 clearfix"
                    action="https://bookings.genesishotels.online/room_list" 
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input1_wrapper">
                          <label>Check in</label>
                          <div className="input1_inner">
                            <input
                              type="date"
                              className="form-control input datepicker"
                              placeholder="Check in"
                            />
                          </div>
                        </div>
                      </div>
                      <br/>
                      <div className="col-md-12">
                        <div className="input1_wrapper">
                          <label>Check out</label>
                          <div className="input1_inner">
                            <input
                              type="date"
                              className="form-control input datepicker"
                              placeholder="Check out"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="select1_wrapper">
                          <label>Adults</label>
                          <div className="select1_inner">
                            <select
                              className="select2 select"
                              style={{ width: "100%" }}
                            >
                              <option value={0}>Adults</option>
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="select1_wrapper">
                          <label>Children</label>
                          <div className="select1_inner">
                            <select
                              className="select2 select"
                              style={{ width: "100%" }}
                            >
                              <option value={0}>Children</option>
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="btn-form1-submit mt-15"
                        >
                          Check Availability
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Reservation;
