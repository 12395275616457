import React from "react";

function AboutTile() {
  return (
    <section className="about section-padding">
      <div className="container">
        <div className="row">
          <div
            className="col-md-6 mb-30"
          >
            <span>
              <i className="star-rating" />
              <i className="star-rating" />
              <i className="star-rating" />
              <i className="star-rating" />
              <i className="star-rating" />
            </span>
            <div className="section-subtitle">Genesis Hotels</div>
            <div className="section-title">Enjoy a Luxury Experience</div>
            <p>
              Welcome to the best five-star deluxe hotel in Nigeria. We believe in giving you the best comfort ever. Live in the elegant comfort we have in store for you. Be in a class of your own when you stay with us. Let us make your stay the most memorable...Experience Us!
            </p>
            <p>
              We've got the best locations to capture your memorable moments. Book a room with us and enjoy delicious healthy meals to boost your immune system. We bring you the perfect relaxation.
            </p>
            {/* call */}
            <div className="reservations">
              <div className="icon">
                <span className="flaticon-call" />
              </div>
              <div className="text">
                <p>Reservation</p> <a href="tel:08050499247">08050499247</a>
              </div>
            </div>
          </div>
          <div
            className="col col-md-3"
          >
            <img src={require("../assets/images/banners/img_1.webp")} alt className="mt-90 mb-30" />
          </div>
          <div
            className="col col-md-3"
          >
            <img src={require("../assets/images/banners/img_2.webp")} alt />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutTile;
