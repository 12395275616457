import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminDetails: null,
  
};

export const adminSlice = createSlice({
  name: "adminUsers",
  initialState,
  reducers: {
    setAdminUsers : (state, action) =>{
      state.adminDetails = action.payload;
    },
   
  },
});

export const {
    setAdminUsers
} = adminSlice.actions;
export default adminSlice.reducer;
