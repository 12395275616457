import React from 'react'
import AboutTile from '../components/AboutTile'
import BookingWrapper from '../components/BookingWrapper'
import Clients from '../components/Clients'
import Facilities from '../components/Facilities'
import Header from '../components/Header'
import WebsiteLayout from '../components/layouts/WebsiteLayout'
import OurHotel from '../components/OurHotel'
import PromoVideo from '../components/PromoVideo'
import Reservation from '../components/Reservation'
import Services from '../components/Services'
import ServiceTile from '../components/ServiceTile'
import Testimonials from '../components/Testimonials'

function Home() {
  return (
    <WebsiteLayout>
        <Header/>
        <BookingWrapper/>
        <AboutTile/>
        <OurHotel/>
        <Services/>
        <PromoVideo/>
        <Facilities/>
        <Testimonials/>
        <ServiceTile/>
        <Reservation/>
        <Clients/>
    </WebsiteLayout>
  )
}

export default Home