import React, { useEffect, useState } from 'react'
import AdminLayout from '../../components/layouts/AdminLayout'
import { useQuery } from '@tanstack/react-query'
import { getAllHotels, getReservations } from '../../api/admin.api'
import { toast } from 'react-toastify'
import { displayAmount, makeTitleCase } from '../../utils/helpers'
import moment from "moment";
import { FaEye } from 'react-icons/fa'
import ReservationModal from '../../components/Admin/ReservationModal'
import { Form, Spinner } from "react-bootstrap";
import { useSelector } from 'react-redux'

const Bookings = () => {
  const { adminDetails } = useSelector(state=>state.admin)
  const isSuperAdmin = !adminDetails?.hotelId
    const [bookingData, setBookingData] = useState(null)
    const [showBookingModal, setShowBookingModal] = useState(false)
    const [currentBooking, setCurrentBooking] = useState(null)
    const [allHotels, setAllHotels] = useState([])
    const [currentHotel, setCurrentHotel] = useState(isSuperAdmin?"all":adminDetails?.hotelId?._id)
    const [period, setPeriod] = useState("month");
    const [data, setData] = useState({})


    const {isLoading:fetchingHotels} = useQuery({
      queryKey:['getHotels'],
      queryFn: getAllHotels,
      enabled: isSuperAdmin,
      onError: err => {
        return toast.error("Error fetching hotels")
      },
      onSuccess: data => {
        if (!data.status && data.errors) {
          return toast.error(typeof data.errors[0].msg == "string"
            ? data.errors[0].msg
            : data.errors[0].msg.message)
        }
        setAllHotels(data.value)
      }
    }) 

    const {isLoading:fetchingReservations} = useQuery({
        queryKey: ['reservations', currentHotel, period],
        queryFn: getReservations,
        onSuccess: data => {
            if (!data.status && data.errors) {
                return toast.error(typeof data.errors[0].msg == "string"
                  ? data.errors[0].msg
                  : data.errors[0].msg.message)
              }
            setBookingData(data.value)
        }
    })

    useEffect(() => {
     if(currentBooking){
      setShowBookingModal(true)
     }
    }, [currentBooking])
    
    const handleModalClose = () => {
      setShowBookingModal(false)
      setCurrentBooking(null)
    }

  const handleHotelChange = (e) => {
    let hotel = e.target.value
    setCurrentHotel(hotel)

  }
  const handlePeriodChange = (e) => {
    if(e.target.value === "date"){
      // setShowRangePicker(true)
      return;
    }
    setData({})
    setPeriod(e.target.value);
  }
  return (
    <AdminLayout>
         
         <div className="d-sm-flex align-items-center justify-content-between mb-4" >
         <h1 className="h3 mb-0 text-gray-800">Bookings</h1>
          <div style={{ display: "flex" }}>
          {isSuperAdmin && <Form.Group className="mb-3 mr-3" controlId="formSelect">
                <Form.Select
                  aria-label="filter-duration"
                  onChange={handleHotelChange}
                  value={currentHotel}
                >
                  <option selected disabled value="">Filter</option>
                  <option value="all">All Hotels</option>
                  {
                    allHotels.map((item, index) => <option key={`hdhwr-${Math.floor(Math.random() * 9999999999)}`} value={item?._id}>{makeTitleCase(item?.name)}</option>)
                  }
                </Form.Select>
              </Form.Group>}

            <Form.Group className="mb-3" controlId="formSelect">
              <Form.Select
                aria-label="filter-duration"
                onChange={handlePeriodChange}
                value={period}
              >
                <option selected disabled value="">Filter</option>
                <option value="date">Custom Date</option>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="current_week">Current Week</option>
                <option value="last_week">Last Week</option>
                <option value="month">This Month</option>
                <option value="last_month">Last Month</option>
                <option value="all_time">All Time</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>


       <div className="card shadow mb-4">
  <div className="card-header py-3">
    <h6 className="m-0 font-weight-bold text-primary">All Bookings</h6>
  </div>
  <div className="card-body">
    <div className="table-responsive">
      <table className="table table-bordered table-hover table-stripped" id="" width="100%" cellSpacing={0}>
        <thead>
          <tr>
            <th>S/N</th>
            <th>Name</th>
            <th>Hotel</th>
            <th>Room Type</th>
            <th>Arrival</th>
            <th>Departure</th>
            <th>Duration</th>
            <th>Amount</th>
            <th>Date Booked</th>
            <th></th>
          </tr>
        </thead>
        <tfoot>
          <tr>
          <th>S/N</th>
            <th>Name</th>
            <th>Hotel</th>
            <th>Room Type</th>
            <th>Arrival</th>
            <th>Departure</th>
            <th>Duration</th>
            <th>Amount</th>
            <th>Date Booked</th>
            <th></th>
          </tr>
        </tfoot>
        <tbody>
            {
                fetchingReservations && 
                <tr>
                    <td colSpan={7}>Loading...</td>
                </tr>
            }
          {!fetchingReservations && bookingData && bookingData.map((booking, index)=> 
          <tr key={booking.uid}>
            <td>{index + 1}</td>
            <td>{makeTitleCase(booking.firstName+" "+booking.lastName)}</td>
            <td>{booking.hotel.name}</td>
            <td>{booking.room.name}</td>
            <td> {moment(booking.arrival).format("MMM Do YYYY")}</td>
            <td> {moment(booking.departure).format("MMM Do YYYY")}</td>
            <td> {booking.nights} night{booking.nights>1?"s":""}</td>
            <td>{displayAmount(booking.totalPrice,0,booking.currency==="USD"?"$":"₦")}</td>
            <td>{moment(booking.created).format("MMM Do YYYY")}</td>
            <td>
                <button className='text-sm btn-sm btn-primary btn-outline' onClick={()=>setCurrentBooking(booking)}><FaEye/></button>
            </td>
          </tr>)
         }
        </tbody>
      </table>
    </div>
  </div>
</div>

{showBookingModal && <ReservationModal visible={showBookingModal} booking={currentBooking} close={handleModalClose} />}
      
    </AdminLayout>
  )
}

export default Bookings
