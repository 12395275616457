import React, {useRef, useEffect} from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../utils/helpers";
import useWindowDimensions from "./useWindowDimensions";

function BookingWrapper() {
  const arrival = useRef("arrival")
  const departure = useRef("departure")
  const {width} = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(false)
  let history = useNavigate()

  const [formdata, setFormdata] = useState({
    arrival:"",
    departure:"",
    property:"the-sojourner-by-genesis",
    adult:1,
    children:0
  })

  useEffect(() => {
   arrival.current.min = new Date().toISOString().split("T")[0];
   departure.current.min = new Date().toISOString().split("T")[0];
  }, [])

  const handleChange = (field,value) => {
    if(field === "arrival"){
      departure.current.min = value
    }
    setFormdata(prev=>({
      ...prev,
      [field]:value
    }))
  }

  const handleSubmit = (e) => {
      e.preventDefault();
      if(!formdata?.arrival || !formdata?.departure || !formdata?.property || !formdata?.adult) {
        return showAlert({
          title:"Incomplete Fields",
          msg:"Please check that you have entered the right information",
          icon:"error"
        })
      };
      setIsLoading(true)
      // console.log(formdata)
      window.location = (`/hotel/${formdata.property}?ref=booking&arrival=${formdata.arrival}&departure=${formdata.departure}&adult=${formdata.adult}&children=${formdata.children}`)
      // history(`/hotel/${formdata.property}?ref=booking&arrival=${formdata.arrival}&departure=${formdata.departure}&adult=${formdata.adult}&children=${formdata.children}`)
  }
  
  return (
    <div className="booking-wrapper" id="book-now">
      <div className="container">
        <div className="booking-inner clearfix">
        
           <form className="d-flex w-100 book_table_inner booking_form" id="booking-form" 
           onSubmit={handleSubmit}
          //  action="https://bookings.genesishotels.online/room_list" 
           acceptCharset="UTF-8" method="post"
            style={{padding:"20px 32px", alignItems:width > 767?"flex-end":"unset", background:"#f5f5f5cc"}}
            >
  <input type="hidden" name="property" defaultValue="CLHS" />
  <div className="book_table_item flex-fill mr-2">
    <div className="input-append date" style={{minWidth:"100%"}}>
      <label htmlFor="arrival">Arrival Date</label>
      <input defaultValue className="form-control" onChange={(e)=>handleChange("arrival", e.target.value)
        } ref={arrival} placeholder="Arrival"  type="date" name="arrival" id="arrival" min="" style={{minWidth:"100%"}} value={formdata.arrival} />
    </div>
  </div>
  <div className="book_table_item flex-fill mr-2">
    <div className="input-append date" style={{minWidth:"100%"}}>
    <label htmlFor="departure">Departure Date</label>
      <input defaultValue className="form-control" ref={departure} placeholder="Departure" type="date" name="departure" id="departure" min="" style={{minWidth:"100%"}} onChange={(e)=>handleChange("departure", e.target.value)} value={formdata.departure}/>
    </div>
  </div>
  <div className="book_table_item flex-fill mr-2">
  <label htmlFor="property">Hotel Location</label>
    <select className="custom-select" name="property" id="property" style={{width:"100%"}} onChange={(e)=>handleChange("property", e.target.value)} value={formdata.property}>
      <option value="the-sojourner-by-genesis">The Sojourner by Genesis (Ikeja, Lagos)</option>
      <option value="genesis-reventon-park">Genesis Reventon Park (GRA, Port Harcourt)</option>
      <option value="genesis-wellness-park">Genesis Wellness Park (GRA, Port Harcourt)</option>
      <option value="genesis-place">Genesis Place (Rumuogba, Port Harcourt)</option>
      <option value="genesis-castle">Genesis Castle (Woji, Port Harcourt) </option>
      <option value="genesis-cottage">Genesis Cottage (Rumuogba, Port Harcourt)</option>
      <option value="genesis-jersey-estate">Genesis Jersey Estate (GRA, Port Harcourt)</option>
    </select>
  </div>
  <div className="book_table_item flex-fill mr-2">
  <label htmlFor="guestcount">Adults</label>
    <select className="custom-select" name="guestcount" id="guestcount" style={{width:"100%"}} onChange={(e)=>handleChange("adult", e.target.value)} value={formdata.adult}>
      {/* <option value={0}>Adults</option> */}
      <option value={1}>1</option>
      <option value={2}>2</option>
      <option value={3}>3</option>
    </select>
  </div>
  <div className="book_table_item flex-fill mr-2">
  <label htmlFor="">Children</label>
    <select className="custom-select" name="" id="" style={{width:"100%"}} onChange={(e)=>handleChange("children", e.target.value)} value={formdata.children}>
      <option value={0}>0</option>
      <option value={1}>1</option>
      <option value={2}>2</option>
      <option value={3}>3</option>
    </select>
  </div>
  <button disabled={isLoading} type="submit" name="commit" defaultValue="Check Availability" className="submitBtn book_now_btn" data-disable-with="Check Availability">
  {isLoading?<Spinner animation="border" size="sm"/>:"Check"}
  </button>
</form>

{/* 
<form className="d-flex w-100 book_table_inner" id="booking-form" action="https://bookings.genesishotels.online/room_list" acceptCharset="UTF-8" method="post">
  <input type="hidden" name="property" defaultValue="CLHS" />
  <div className="book_table_item flex-fill mr-2">
    <div className="input-append date">
      <input defaultValue className="form-control" placeholder="Arrival" type="date" name="arrival" id="arrival" />
    </div>
  </div>
  <div className="book_table_item flex-fill mr-2">
    <div className="input-append date">
      <input defaultValue className="form-control" placeholder="Departure" type="date" name="departure" id="departure" />
    </div>
  </div>
  <div className="book_table_item flex-fill mr-2">
    <select className="custom-select" name="property" id="property">
      <option value="PLACE">Genesis Place</option>
      <option value="CASTLE">Genesis Castle</option>
      <option value="COTTAGE">Genesis Cottage</option>
      <option value="SOJNER">Sojourner Hotel</option>
    </select>
  </div>
  <div className="book_table_item flex-fill mr-2">
    <select className="custom-select" name="guestcount" id="guestcount">
      <option value={0}>Adults</option>
      <option value={1}>1</option>
      <option value={2}>2</option>
      <option value={3}>3</option>
    </select>
  </div>
  <input type="submit" value="Book Now" name="commit" defaultValue="Check Availability" className="book_now_btn" data-disable-with="Check Availability" />
</form> */}
        </div>
      </div>
    </div>
  );
}

export default BookingWrapper;
