import React from 'react'
import WebsiteLayout from '../components/layouts/WebsiteLayout'
import Navbar from '../components/Navbar'

const OrderDetails = () => {
  return (
    <WebsiteLayout>
    <Navbar />

    </WebsiteLayout>
  )
}

export default OrderDetails