import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Navbar from '../Navbar'
import "../../assets/css/style.css"
import JerseyFooter from '../JerseyFooter'
function JerseyWebsiteLayout({children}) {
  return (
    <>
        <Navbar/>
            {children}
        <JerseyFooter/>
    </>
  )
}

export default JerseyWebsiteLayout