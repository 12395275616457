import React from 'react'
import WebsiteLayout from '../components/layouts/WebsiteLayout'
import Header from '../components/Discount15/Header'
import DescriptionSection from '../components/Discount15/DescriptionSection'
import Services from '../components/Discount15/Services'
import Clients from '../components/Clients'

const Discount15 = () => {
  return (
    <WebsiteLayout>
        <Header/>
        <DescriptionSection/>
        <Services/>
        <Clients/>
    </WebsiteLayout>
  )
}

export default Discount15
