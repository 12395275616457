import axios from "axios"
import { API_URL } from "../configs/app"

export const getReportData = async ({queryKey}) => {
        let response = await axios.get(`${API_URL}/report?hotelid=${queryKey[1]}&period=${queryKey[2]}`)
    return response.data
}

export const getReservations = async ({queryKey}) => {
    let response = await axios.get(`${API_URL}/report/reservations?hotelid=${queryKey[1]}&period=${queryKey[2]}`)
    return response.data
}

export const getFeedbacks = async () => {
    let response = await axios.get(`${API_URL}/report/feedbacks`)
    return response.data
}
export const getAllHotels = async () => {
    let response = await axios.get(`${API_URL}/hotels`)
    return response.data
}