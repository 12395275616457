import React from "react";

function Header({image, title, subtitle, rating=0}) {
  return (
    <div
      className="bg-center banner-header section-padding valign bg-img bg-fixed"
      data-overlay-dark={4}
      style={{background:`url(${image})`}}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 caption mt-90">
            <span>
              {Array(rating).fill().map(x=><i key={Math.random()*9999999} className="star-rating" />)}
            </span>
            <h5>{subtitle}</h5>
            <h1>{title}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
