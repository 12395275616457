import React from 'react'
import "../../assets/css/admin.css"
import "../../assets/css/style.css"

const AuthLayout = ({children}) => {
  return (
    <div className="container">
    {/* Outer Row */}
    <div className="row justify-content-center">
      <div className="col-xl-10 col-lg-12 col-md-9">
        <div className="card o-hidden border-0 shadow-lg my-5">
          <div className="card-body p-0">
{children}
          </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default AuthLayout
