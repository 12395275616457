import React from "react";
import OwlCarousel from 'react-owl-carousel';

function RoomHeader({images}) {
  return (
    <header className="header slider">
      {/* <div className="owl-carousel owl-theme"> */}
      <OwlCarousel  className="owl-theme" loop startPosition={0} dots={true} center autoplay autoplayTimeout={3000} items={1}>
       {
        images.map(image=> <div
        key={`hfhd-${Math.random()*999999}`}
            className="text-center item bg-img"
            data-overlay-dark={2}
            style={{background:`url(${image})`, backgroundPosition:"center", backgroundSize:"cover"}}
          />)
       }
      </OwlCarousel>
      {/* </div> */}
      {/* arrow down */}
      <div className="arrow bounce text-center">
        <a href="#" data-scroll-nav={1} className="">
          {" "}
          <i className="ti-arrow-down" />{" "}
        </a>
      </div>
       
    </header>
  );
}

export default RoomHeader;
