import React from "react";
import AdminLayout from "../../components/layouts/AdminLayout";
import MenuFilter from "../../components/Admin/MenuFilter";
import { FaPencilAlt, FaPlus, FaTrashAlt } from "react-icons/fa";
import FAB from "../../components/Admin/FAB";
import CreateModal from "../../components/Admin/Modals/CreateModal";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { fetchMenuItems } from "../../api/menu.api";
import { displayAmount, makeTitleCase } from "../../utils/helpers";
import MenuTable from "../../components/Admin/Tables/MenuTable";

const FoodMenu = () => {
  const [showModal, setShowModal] = React.useState(false);
  const {menuType, menuCategory, menuSearch} = useSelector(state=>state.menu)
  const { adminDetails } = useSelector(state=>state.admin)

const {data:menuItems, isLoading:loadingMenuItems} = useQuery({
  queryKey:["getItems",adminDetails?.hotelId?._id, menuType, menuCategory, menuSearch],
  queryFn: fetchMenuItems
}) 
  return (
    <AdminLayout>
      <MenuFilter />
      <MenuTable loadingMenuItems={loadingMenuItems} menuItems={menuItems} />
      {showModal && <CreateModal close={() => setShowModal(false)} />}
      <FAB onClick={() => setShowModal(true)} />
    </AdminLayout>
  );
};

export default FoodMenu;
