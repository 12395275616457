import React, { useEffect, useState } from 'react'
import WebsiteLayout from '../components/layouts/WebsiteLayout'
import BlogHeader from '../components/Blog/BlogHeader'
import Header from '../components/Hotel/Header'
import { getBlogPosts } from '../utils/blog'
import BlogBlock from '../components/Blog/BlogBlock'

const image = require("../assets/images/banners/sojourner_front.webp")

const Blog = () => {
const [blogposts, setBlogposts] = useState([])
    const fetchPost = async () => {
        let posts = await getBlogPosts()
        setBlogposts(posts);
        console.log(posts)
    }

    useEffect(() => {
        fetchPost()
    }, [])
    
   
  return (
    <WebsiteLayout>
         <Header image={image} title="Our Blog" subtitle="Genesis Hotels" />
      
        <section className="news2 section-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="row">
       {
        blogposts.map(post=><BlogBlock post={post}/>)
       }
        

          {/* <div className="col-md-12">
            <ul className="news-pagination-wrap align-center mb-30 mt-30">
              <li><a href="blog2.html#"><i className="ti-angle-left" /></a></li>
              <li><a href="blog2.html#">1</a></li>
              <li><a href="blog2.html#" className="active">2</a></li>
              <li><a href="blog2.html#">3</a></li>
              <li><a href="blog2.html#"><i className="ti-angle-right" /></a></li>
            </ul>
          </div> */}
        </div>
      </div>
    
    </div>
  </div>
</section>

    </WebsiteLayout>
  )
}

export default Blog
