import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { makeTitleCase } from '../../utils/helpers'

const BlogBlock = ({post}) => {
    let thumbnailImage = post.fields.thumbnail?.fields.file.url

  return (
    <div className="col-md-6">
    <div className="item">
      <div className="post-img">
        <Link to={`/post/${post.fields.title}/${post.sys.id}`}>
            <img src={thumbnailImage} alt="" />
        </Link>

        <div className="date">
          <Link to={`/post/${post.fields.title}/${post.sys.id}`}>
            <span>{moment(post.sys.createdAt).format("MMM")}</span>
            <i>{moment(post.sys.createdAt).format("D")}</i> 
          </Link>
        </div>
      </div>
      <div className="post-cont">
        <h5>
        <Link to={`/post/${post.fields.title}/${post.sys.id}`}>
            {makeTitleCase(post.fields.title)}
        </Link>
        </h5>
        <p>
            {post.fields.excerpt}
        </p>
        <div className="butn-dark btn"> 
        <Link to={`/post/${post.fields.title}/${post.sys.id}`}><span>Read More</span></Link> </div>
      </div>
    </div>
  </div>
  )
}

export default BlogBlock
