import React, { useState } from 'react'
import AuthLayout from '../components/layouts/AuthLayout'
import { useMutation } from '@tanstack/react-query'
import { loginAdmin } from '../api/auth.api'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { setAdminUsers } from '../store/slices/adminSlice'
import { cookieStore } from '../utils/cookie'
import { useNavigate } from 'react-router-dom'
import { COOKIE_KEY } from '../configs/app'

const Login = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    })

    const { mutate: handleLogin, isLoading: loadingLogin } = useMutation(loginAdmin, {
        onSuccess: data => {
            // console.log(data)
            if (!data.status && data.errors) {
                return toast.error(typeof data.errors[0].msg == "string"
                    ? data.errors[0].msg
                    : data.errors[0].msg.message)
            }
            toast.success("Login Successful")
            console.log(data?.value?.admin)
            dispatch(setAdminUsers(data?.value?.admin))
            cookieStore(COOKIE_KEY, data?.value?.token);
            // console.log(2)
            history("/admin/dashboard");

        },
        onError: err => {
            return toast.error("An internal error has occured")
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        handleLogin(formData)

    }
    return (
        <AuthLayout>
            <div className="row">
                <div className="col-lg-6 offset-lg-3">
                    <div className="p-5">
                        <div className="text-center">
                            <h1 className="h4 text-gray-900 mb-4">Login to Admin</h1>
                        </div>
                        <form className="user" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input value={formData.username} onChange={(e) => setFormData(prev => ({ ...prev, username: e.target.value }))} type="text" className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Username" />
                            </div>
                            <div className="form-group">
                                <input type="password" value={formData.password} onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))} className="form-control form-control-user" id="exampleInputPassword" placeholder="Password" />
                            </div>

                            <button type="submit" className="btn btn-danger btn-user btn-block my-3" disabled={loadingLogin}>
                                {loadingLogin ? "PROCESSING..." : "LOGIN"}
                            </button>

                        </form>
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}

export default Login
