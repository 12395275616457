import React from 'react'
import Sidebar from '../Admin/Sidebar'
import TopNav from '../Admin/TopNav'

const AdminLayout = ({ children }) => {
  return (
    <div id="wrapper">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopNav/>
          <div className="container-fluid">
          {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminLayout
