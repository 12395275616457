import React, { useState } from 'react'
import { displayAmount, makeTitleCase } from '../../utils/helpers'
import moment from 'moment'

const ReservationModal = ({visible, booking, close}) => {
  return (<>
      { booking && <>
      <div className={`modal-backdrop fade ${visible?"show":""}`}> </div>
      <div className={`modal fade  ${visible?"show":""}`} style={{display: "block"}} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content" style={{maxHeight: "calc(100vh - 100px)", overflowY: "auto"}}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Booking #{booking.bookingNumber}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Customer Details</h5>
                      <p className="card-text">Name: <span className='bold'>{makeTitleCase(booking.firstName +" "+ booking.lastName)}</span></p>
                      <p className="card-text">Email: <span className='bold'>{booking.email}</span></p>
                      <p className="card-text">Phone: <span className='bold'>{booking.phone}</span></p>
                      <p className="card-text">Address: <span className='bold'>{booking.address}</span></p>
                      <p className="card-text">Sate: <span className='bold'>{booking.state}</span></p>
                      <p className="card-text">Country: <span className='bold'>{booking.country}</span></p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card card-success">
                    <div className="card-body">
                      <h5 className="card-title">Booking Details</h5>
                      <p className="card-text">Booking number: <span className=''> {booking.bookingNumber}</span></p>
                      <p className="card-text">Hotel: <span className='bold'> {booking.hotel.name}</span></p>
                      <p className="card-text">Room Type: <span className='bold'> {booking.room.name}</span></p>
                      <p className="card-text">No of Nights: <span className='bold'> {booking.nights}</span></p>
                      <p className="card-text">Arrival Date: <span className='bold'>  {moment(booking.arrival).format("dddd MMM Do, YYYY")}</span></p>
                      <p className="card-text">Departure Date: <span className='bold'>  {moment(booking.departure).format("dddd MMM Do, YYYY")}</span></p>
                      <p className="card-text">No of Guest: <span className='bold'> {booking.guest}</span></p>
                      <p className="card-text">Unit Cost: <span className='bold'> {displayAmount(booking.unitPrice,0,booking.currency==="USD"?"$":"₦")}</span></p>
                      <p className="card-text">Total Paid: <span className='bold'> {displayAmount(booking.totalPrice,0,booking.currency==="USD"?"$":"₦")}</span></p>
                    
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Additional Details</h5>
                      <p className="card-text">Extra note: <span className='bold'>{booking.note}</span></p>
                    </div>
                  </div>
            </div>
            </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={close}>Close</button>
            </div>
          </div>
        </div>
      </div>
      </>}
     
      </>)
}

export default ReservationModal
