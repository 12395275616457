import axios from "axios"
import { API_URL, OWS_BASE_URL } from "../configs/app"

export const initiateBooking = async (data) => {
    let response = await axios.post(`${API_URL}/bookings/create`, data);
    return response.data
}

export const makeBookingOnOWS = async (data) => {
    let response = await axios.post(`${OWS_BASE_URL}/reservation`, data)
    return response.data
}

export const getSingleBooking = async ({queryKey}) => {
    let response = await axios.get(`${API_URL}/bookings/single/${queryKey[1]}`)
    return response.data
}