import React from "react";
import OwlCarousel from "react-owl-carousel";

function Clients() {
  return (
    <section className="clients">
      <div className="container">
        <div className="row">
          {/* <div className="col-md-7 owl-carousel owl-theme"> */}
          <OwlCarousel  className="owl-theme" nav loop startPosition={0} dots={true}  autoplay autoplayTimeout={5000} items={5}>
       
            <div className="clients-logo">
              <a href="#0">
                <img src={require("../assets/images/logos/sojourner_genesis.png")} style={{width:"70%", alignItems:"center", margin:"auto"}} alt />
              </a>
            </div>
            <div className="clients-logo">
              <a href="#0">
              <img src={require("../assets/images/logos/genesis_place.png")} style={{width:"70%", alignItems:"center", margin:"auto"}} alt />
              </a>
            </div>
            <div className="clients-logo">
              <a href="#0">
              <img src={require("../assets/images/logos/genesis_hotels.png")} style={{width:"70%", alignItems:"center", margin:"auto"}} alt />
              </a>
            </div>
            <div className="clients-logo">
              <a href="#0">
              <img src={require("../assets/images/logos/genesis_cottage.png")} style={{width:"70%", alignItems:"center", margin:"auto"}} alt />
              </a>
            </div>
            <div className="clients-logo">
              <a href="#0">
              <img src={require("../assets/images/logos/genesis_castle.png")} style={{width:"70%", alignItems:"center", margin:"auto"}} alt />
              </a>
            </div>
            </OwlCarousel>
          {/* </div> */}
        </div>
      </div>
    </section>
  );
}

export default Clients;
