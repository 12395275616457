import React from "react";
import { Link } from "react-router-dom";

function ServiceTile() {
  return (
    <section className="services section-padding">
      <div className="container">
        <div className="row">
          <div
            className="col-md-6 p-0"
          >
            <div className="img left">
              <a href="/hotel">
                <img src={require("../assets/images/rest2.webp")} alt />
              </a>
            </div>
          </div>
          <div
            className="col-md-6 p-0 bg-cream valign"
          >
            <div className="content">
              <div className="cont text-left">
                <div className="info">
                  <h6>Discover</h6>
                </div>
                <h4>The Restaurant</h4>
                {/* <p>
                  Restaurant inilla duiman at elit finibus viverra nec a lacus
                  themo the nesudea seneoice misuscipit non sagie the fermen
                  ziverra tristiue duru the ivite dianne onen nivami acsestion
                  augue artine.
                </p> */}
                <div className="butn-dark">
                  {" "}
                  <a href="/hotel">
                    <span>Learn More</span>
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-6 bg-cream p-0 order2 valign"
          >
            <div className="content">
              <div className="cont text-left">
                <div className="info">
                  <h6>Enjoy</h6>
                </div>
                <h4>Serene Environment</h4>
                {/* <p>
                  Spa center inilla duiman at elit finibus viverra nec a lacus
                  themo the nesudea seneoice misuscipit non sagie the fermen
                  ziverra tristiue duru the ivite dianne onen nivami acsestion
                  augue artine.
                </p> */}
                <div className="butn-dark">
                  {" "}
                  <a href="/hotel">
                    <span>Learn More</span>
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 p-0 order1"
          >
            <div className="img">
              <a href="spa-wellness.html">
                <img src={require("../assets/images/serene.webp")} alt />
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-6 p-0 "
          >
            <div className="img left">
              <a href="/hotel">
              <img src={require("../assets/images/gymm.webp")} alt />
              </a>
            </div>
          </div>
          <div
            className="col-md-6 p-0 bg-cream valign "
          >
            <div className="content">
              <div className="cont text-left">
                <div className="info">
                  <h6>Modern</h6>
                </div>
                <h4>Fitness Center</h4>
                {/* <p>
                  Restaurant inilla duiman at elit finibus viverra nec a lacus
                  themo the nesudea seneoice misuscipit non sagie the fermen
                  ziverra tristiue duru the ivite dianne onen nivami acsestion
                  augue artine.
                </p> */}
                <div className="butn-dark">
                  {" "}
                  <a href="/hotel">
                    <span>Learn More</span>
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-6 bg-cream p-0 order2 valign"
          >
            <div className="content">
              <div className="cont text-left">
                <div className="info">
                  <h6>Experiences</h6>
                </div>
                <h4>The Pool</h4>
                {/* <p>
                  The health club &amp; pool at elit finibus viverra nec a lacus
                  themo the nesudea seneoice misuscipit non sagie the fermen
                  ziverra tristiue duru the ivite dianne onen nivami acsestion
                  augue artine.
                </p> */}
                <div className="butn-dark">
                  {" "}
                  <a href="/hotel">
                    <span>Learn More</span>
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 p-0 order1"
          >
            <div className="img">
              <a href="/hotel">
              <img src={require("../assets/images/spool.webp")} alt />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceTile;
