import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAdminUsers, setDetails } from '../../store/slices/adminSlice';
import { cookieRemove } from '../../utils/cookie';
import { COOKIE_KEY } from '../../configs/app';

function Logout() {
const history = useNavigate();
let dispatch = useDispatch()
  const handleLogout = async () => {
    await cookieRemove(COOKIE_KEY);
    dispatch(setAdminUsers(null))
    history('/auth/login')
  }
  useEffect(() => {
    handleLogout();
  }, [])
  
  return <></>
}

export default Logout