import React, { useState } from "react";
import { makeTitleCase, showAlert } from "../../../utils/helpers";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { fetchMenuCategories, updateMenuItem } from "../../../api/menu.api";
import { queryClient } from "../../../App";

const EditMenuModal = ({ close, item }) => {
const [formdata, setFormdata] = useState(item)
let { adminDetails } = useSelector((state) => state.admin);

const {mutate:updateMenu, isLoading:savingMenu} = useMutation({
    mutationKey:["updateMenuItem"],
    mutationFn: updateMenuItem,
    onError: error => {
      showAlert({
        title: "Error",
        msg: "An internal error occurred. Please try again.",
        icon: "error",
        button: "Try again",
      });
    },
    onSuccess: data => {
    //   console.log(data)
      if (!data.status) {
        showAlert({
          title: "Error",
          msg: "Failed to update menu. Please try again.",
          icon: "error",
          button: "Try again",
        });
      } else {
        showAlert({
          title: "Success",
          msg: "Item updated successfully.",
          icon: "success",
          button: "Done",
        });
        queryClient.invalidateQueries(["getItems"]);
        close();
      }
    }
  })

const handleChange = (type, value) => {
    setFormdata(prev=>({
        ...prev,
        [type]:value
    }))
}

const handleSubmit = (e) => {
    e.preventDefault();
    if ( !formdata?.type || !formdata?.name || !formdata?.menuCategoryId || !formdata?.amount ) {
        return  showAlert({
          title: "Error",
          msg: "Please fill in all fields.",
          icon: "error",
          button: "OK",
        });
      }
    updateMenu({
        ...formdata,
        id:item._id
    })
}

    const {data:allCategory} = useQuery({
        queryKey:["allCategory", adminDetails?.hotelId?._id, formdata?.type ],
        queryFn: fetchMenuCategories,
        enabled: (!!formdata?.type)
      })

  return (
    <div
      className="modal d-block"
      id="exampleModal"
      tabIndex={-1}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div
          className="modal-content"
          style={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)" }}
        >
          <div className="modal-header">
            <h5 className="modal-title">Edit {makeTitleCase(item?.name)}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={close}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
           
          <form onSubmit={handleSubmit}>
              <div className="row my-3">
                <div className="form-group mb-3 col-lg-6">
                  <label htmlFor="menuType">Menu Type</label>
                  <select
                    value={formdata?.type}
                    className="select form-control"
                    id="menuType"
                    onChange={(e) => handleChange("type", e.target.value)}
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    <option value={"food"}>Food</option>
                    <option value={"drinks"}>Drinks</option>
                  </select>
                </div>
                <div className="form-group mb-3 col-lg-6">
                  <label htmlFor="itemCategory">Category</label>
                  <select
                    value={formdata?.menuCategoryId?._id}
                    className="select form-control"
                    id="itemCategory"
                    onChange={(e) => handleChange("menuCategoryId",e.target.value)}
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {allCategory?.value?.map((item, index) => (
                      <option key={`hgdg-${index}`} value={item?._id}>
                        {makeTitleCase(item?.name)}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-lg-6">
                  <label htmlFor="itemName">Item Name</label>
                  <input
                    type="text"
                    className="input form-control"
                    id="itemName"
                    placeholder="Enter item name"
                    value={formdata?.name}
                    onChange={(e) => handleChange("name",e.target.value)}
                  />
                </div>
                <div className="form-group col-lg-6">
                  <label htmlFor="itemPrice">Price</label>
                  <input
                    type="number"
                    className="form-control"
                    id="itemPrice"
                    placeholder="Enter price"
                    value={formdata?.amount}
                    onChange={(e) => handleChange("amount",e.target.value)}
                  />
                </div>
                <div className="form-group col-lg-12">
                  <label htmlFor="itemDescription">
                    Description <small>(optional)</small>
                  </label>
                  <textarea
                    cols={3}
                    rows={3}
                    name="itemDescription"
                    id="itemDescription"
                    value={formdata?.description}
                    onChange={(e) => handleChange("description",e.target.value)}
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className={`btn  btn-lg my-3 
                  ${savingMenu ? "btn-secondary" : "btn-primary"}
                  `}
                  disabled={savingMenu}
                >
                  {savingMenu ? "Saving..." : "Update Menu Item"}
                </button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMenuModal;
