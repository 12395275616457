import client from "../libs/contentful"


export const getBlogPosts = async () => {
    const entries = await client.getEntries({
        content_type:"blogPost"
    })
    if (entries.items) return entries.items
    console.log(`Error getting Entries.`)
  }

  export const getSingleBlogPost = async (ref) => {
    let post = await client.getEntry(ref)
    return post
  }