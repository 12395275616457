import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'
import { fetchSingleHotelBySlug } from '../../api/hotels.api';
import { useParams } from 'react-router-dom';
import { showAlert } from '../../utils/helpers';
import JerseyWebsiteLayout from './JerseyLayout';
import WebsiteLayout from './WebsiteLayout';

const SingleHotelLayout = ({children, hotel}) => {
    // const { slug } = useParams();
    // const [hotel, setHotel] = useState(null);
    // const { isLoading: loadingHotel } = useQuery(
    //     ["hotels", slug],
    //     fetchSingleHotelBySlug,
    //     {
    //       onSuccess: (data) => {
    //         // console.log(data)
    //         setHotel(data.value);
    //       },
    //       onError: (err) => {
    //         console.log(err);
    //         return showAlert({
    //           title:"Error",
    //           msg:"An internal error occured, Try again.",
    //           icon:"error",
    //         })
    //       },
    //     }
    //   );
  return (
    hotel?.name === "Jersey Apartments"?
    <JerseyWebsiteLayout>
        {children}
    </JerseyWebsiteLayout>
    :
     <WebsiteLayout>
        {children}
     </WebsiteLayout>
  )
}

export default SingleHotelLayout
