import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setStoreCurrency } from "../store/slices/appSlice";
import { ReactComponent as CheckMark} from "../assets/images/check.svg"

let feedbackUrl = "https://www.genesisfeedback.store/hotel/sojourner"
// https://www.google.com/travel/hotels/genesis%20hotels%20port%20harcourt/entity/CgoI-NDNmufwl_JdEAE/reviews?q=genesis%20hotels%20port%20harcourt&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4308226%2C4518327%2C4597339%2C4703207%2C4718358%2C4723331%2C4757164%2C4786958%2C4790928%2C4794648%2C4814050%2C4816977%2C4825641%2C4828448&hl=en-GB&gl=uk&cs=1&ssta=1&rp=EPjQzZrn8JfyXRD40M2a5_CX8l04AkAASAHAAQI&ictx=1&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABpJCisSJzIlMHgxMDY5Y2Y3MjgwYTgyN2NkOjB4NWRlNDVmODY3MzUzNjg3OBoAEhoSFAoHCOYPEAkYBhIHCOYPEAkYBxgBMgIQACoJCgU6A0dCUBoA&ved=0CAAQ5JsGahcKEwi4lMW83uH5AhUAAAAAHQAAAAAQAw
function SojournerNavbar() {
    const {pathname} = useLocation()
    let dispatch = useDispatch()
   let { currency } = useSelector(state=>state.app)
    const handleCurrencyChange = (type) => {
      dispatch(setStoreCurrency(type))
    }
  return (
    <nav className="navbar navbar-expand-lg" 
    style={{position:"fixed"}}
    >
      <div className="container">
        {/* Logo */}
        <div className="logo-wrapper navbar-brand valign">
          <a href="/">
            <div className="logo">
              <img src={require("../assets/images/logos/sojourner_genesis.png")} className="logo-img" alt="" />
            </div>
          </a>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          {" "}
          <span className="icon-bar">
            <i className="ti-line-double" />
          </span>{" "}
        </button>
        {/* Navbar links */}
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item">
              {" "}
              <a href="/" className={`nav-link ${pathname == "/"?"active":null} `}>
                Home
              </a>
            </li> */}
            <li className="nav-item">
              <a className={`nav-link ${pathname == "/hotel"?"active":null} `} href="/hotel">
                Our Hotels
              </a>
            </li>
            <li className="nav-item dropdown">
                <a href="https://menu.genesishotels.online/menu" target="_blank" className={`nav-link ${pathname == "/menu"?"active":null} `}>
                    Food &amp; Drinks Menu
                </a>
            </li>

            {/* <li className="nav-item">
                <a href="/recreation" className={`nav-link ${pathname == "/recreation"?"active":null} `}>
                    Recreation
                </a>
            </li>
            <li className="nav-item">
                <a href="/digital" className={`nav-link ${pathname == "/digital"?"active":null} `}>
                    Digital Experience
                </a>
            </li>*/}
            {/* <li className="nav-item">
                <a href="/gallery" className={`nav-link ${pathname == "/gallery"?"active":null} `}>
                   Gallery
                </a>
            </li>  */}
           
            <li className="nav-item">
                <a 
                href={feedbackUrl} target="_blank" className="nav-link">
                    Drop a Feedback
                </a>
            </li>
            <li className="nav-item">
                <a href="/blog" className={`nav-link ${pathname == "/blog"?"active":null} `}>
                    Our Blog
                </a>
            </li>
            <li className="nav-item">
                <a href="/contact" className={`nav-link ${pathname == "/contact"?"active":null} `}>
                    Contact
                </a>
            </li>
            <li className="nav-item dropdown"> 
            <span className="nav-link active"> 
            {currency === "NGN" && "Currency ₦NGN"}
            {currency === "USD" && "Currency $USD"}
            <i className="ti-angle-down" />
            </span>
  <ul className={`dropdown-menu last`} style={{display:"block"}}>
    <li className={`dropdown-item ${currency === "NGN"? "active":""}`} onClick={()=>handleCurrencyChange('NGN')} style={{display:"flex", alignItems:"center"}}>
      {currency === "NGN" && <CheckMark style={{width:12, height:12, color:"red", marginRight:10}}/>}
      <span> ₦ NGN</span>
      </li>
    <li className={`dropdown-item ${currency === "USD"? "active":""}`} onClick={()=>handleCurrencyChange('USD')} style={{display:"flex", alignItems:"center"}}>
       {currency === "USD" && <CheckMark style={{width:12, height:12, color:"red", marginRight:10}}/>}
       <span>$ USD</span>
       </li>
  </ul>
</li>

           
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default SojournerNavbar;
