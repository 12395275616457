import React, { useState } from 'react'
import { displayAmount, makeTitleCase, showAlert } from '../../../utils/helpers'
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa'
import EditMenuModal from '../Modals/EditMenuModal'
import { useMutation } from '@tanstack/react-query'
import { deleteMenuItem } from '../../../api/menu.api'
import { queryClient } from '../../../App'

const MenuItem = ({item, index}) => {
    const [openEditModal, setOpenEditModal] = useState(false)
    const handleEdit = (e) => {
        e.preventDefault();
        setOpenEditModal(true)
    }

    const handleDelete = (e) => {
        e.preventDefault();
        let confirm = window.confirm("Are you sure you want to delete this item?")
        if(confirm){
            deleteItem({id:item?._id})
        }
    }
    const {mutate:deleteItem} = useMutation({
        mutationKey:["deleteItem"],
        mutationFn:deleteMenuItem,
        onError: error => {
            showAlert({
              title: "Error",
              msg: "An internal error occurred. Please try again.",
              icon: "error",
              button: "Try again",
            });
          },
          onSuccess: data => {
            if (!data.status) {
              showAlert({
                title: "Error",
                msg: "Failed to delete item. Please try again.",
                icon: "error",
                button: "Try again",
              });
            } else {
              showAlert({
                title: "Success",
                msg: "Item deleted successfully.",
                icon: "success",
                button: "Done",
              });
              queryClient.invalidateQueries(["getItems"]);
            }
          }
    })
  return (
    <>
    <tr key={`bdhgd-${index}`}>
            <td>{index + 1}</td>
            <td>{makeTitleCase(item?.name)}</td>
            <td><span className="badge badge-info">{makeTitleCase(item?.menuCategoryId?.name)}</span></td>
            <td>{displayAmount(item?.amount || 0)}</td>
            <td>
              <button className="btn btn-sm btn-warning mx-1" onClick={handleEdit}><FaPencilAlt/></button>
              <button className="btn btn-sm btn-danger mx-1" onClick={handleDelete}><FaTrashAlt/></button>
            </td>
          </tr>
          {openEditModal && <EditMenuModal close={()=>setOpenEditModal(false)} item={item}/>}
        </>
  )
}

export default MenuItem
