import React from 'react'
import { FaPlus } from 'react-icons/fa'

const FAB = ({onClick}) => {
  return (
    <div className="floating-action-button" style={{position: 'fixed', bottom: '20px', right: '20px'}}>
    <button onClick={onClick} className="btn btn-primary rounded-circle" style={{width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <FaPlus  />
    </button>
  </div>
  )
}

export default FAB
