import React from 'react'

const DescriptionSection = () => {
  return (
    <section className="rooms-page section-padding" data-scroll-index={1}>
  <div className="container">
    <div className="row">
      <div className="col-md-12"> <span><i className="star-rating" /><i className="star-rating" /><i className="star-rating" /><i className="star-rating" /><i className="star-rating" /></span>
        <div className="section-subtitle">Discover Exclusive Savings</div>
        <div className="section-title">Get 15% discount online</div>
        <p className="mb-30">
        Welcome to Genesis Hotels, where your next unforgettable stay begins with unbeatable savings! We're thrilled to introduce our latest promotion, designed exclusively for savvy travelers like you. When you book your stay directly through our official Genesis Hotels website, you'll unlock an incredible 15% discount on your booking. That's right, it's time to experience luxury for less!
        </p>
      </div>
      <div className="col-md-12">
        <div className="reservations">
          <div className="icon"><span className="flaticon-calendar" /></div>
          <div className="text">
            <p>Book now!</p> <a href="/hotel"><button className='btn btn-danger'>Start Your Booking</button></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default DescriptionSection
