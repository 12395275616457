
import WebsiteLayout from '../components/layouts/WebsiteLayout'
import Header from '../components/Sojourner/Header'
import DescriptionSection from '../components/ValPromo/DescriptionSection'
import Services from '../components/ValPromo/Services'
import Clients from '../components/Clients'
import SojournerLayout from '../components/layouts/SojournerLayout'
const Sojourner = () => {
  return (
    <SojournerLayout>
       <Header/>
        <DescriptionSection/>
        <Services/>
        {/* <Clients/> */}
    </SojournerLayout>
  )
}

export default Sojourner
