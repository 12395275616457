import React from 'react'

const Services = () => {
  return (
    <section className="services section-padding2">
  <div className="container">
    <div className="row">
      <div className="col-md-6 p-0 animate-box" data-animate-effect="fadeInLeft">
        <div className="img left">
          <a href="/hotel"><img src="img/discount/bn1.jpeg" alt /></a>
        </div>
      </div>
      <div className="col-md-6 p-0 bg-cream valign animate-box" data-animate-effect="fadeInRight">
        <div className="content">
          <div className="cont text-left">
            <div className="info">
              <h6>Exclusive</h6>
            </div>
            <h4>Discounts</h4>
            <p>When you book through our website, you get access to special discounts that aren't available on other platforms. You'll be the first to hear about our upcoming promotions and special offers, ensuring you never miss out on fantastic deals. Enjoy significant savings on your next stay</p>
            <p><a href='/hotel'><button className='btn btn-danger btn-lg'>Book Now!</button></a></p>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-6 bg-cream p-0 order2 valign animate-box" data-animate-effect="fadeInLeft">
        <div className="content">
          <div className="cont text-left">
            <div className="info">
              <h6>Best Rate </h6>
            </div>
            <h4>Guaranteed</h4>
            <p> Rest assured, you'll always get the best available rate by booking directly with us. If you find a lower rate elsewhere, we'll match it and provide an extra 10% discount as a gesture of our commitment to your satisfaction.</p>
            <p><a href='/hotel'><button className='btn btn-danger btn-lg'>Book Now!</button></a></p>

          </div>
        </div>
      </div>
      <div className="col-md-6 p-0 order1 animate-box" data-animate-effect="fadeInRight">
        <div className="img">
          <a href="/hotel"><img src="img/discount/bn2.jpeg" alt /></a>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-6 p-0 animate-box" data-animate-effect="fadeInLeft">
        <div className="img left">
          <a href="/hotel"><img src="img/discount/bn3.jpeg" alt /></a>
        </div>
      </div>
      <div className="col-md-6 p-0 bg-cream valign animate-box" data-animate-effect="fadeInRight">
        <div className="content">
          <div className="cont text-left">
            <div className="info">
              <h6>Instant</h6>
            </div>
            <h4>Confirmation</h4>
            <p> Receive instant booking confirmation, so you can plan the rest of your trip with confidence, knowing your accommodations are secured.</p>
            <p><a href='/hotel'><button className='btn btn-danger btn-lg'>Book Now!</button></a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Services
