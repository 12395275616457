import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { createMenuCategory, createMenuItem, fetchMenuCategories } from "../../../api/menu.api";
import { makeTitleCase, showAlert } from "../../../utils/helpers";
import { queryClient } from "../../../App";
import { useSelector } from "react-redux";

const CreateModal = ({ close }) => {
  const [activeTab, setActiveTab] = useState("menuItem");
  const [categoryType, setCategoryType] = useState(null);
  const [menuType, setMenuType] = useState(null)
  const [itemCategory, setItemCategory] = useState(null)
  const [categoryName, setCategoryName] = useState(null);
  const [menuItem, setMenuItem] = useState(null)
  const [itemPrice, setItemPrice] = useState(null)
  const [itemDescription, setItemDescription] = useState(null)
  let { adminDetails } = useSelector((state) => state.admin);

  const { mutate: saveMenuCategory, isLoading:savingCategory } = useMutation({
    mutationKey: ["saveCategory"],
    mutationFn: createMenuCategory,
    onSuccess: (data) => {
      if (!data.status) {
        showAlert({
          title: "Error",
          msg: "Failed to create category. Please try again.",
          icon: "error",
          button: "Try again",
        });
      } else {
        showAlert({
          title: "Success",
          msg: "Category created successfully.",
          icon: "success",
          button: "Done",
        });
        queryClient.invalidateQueries(["menuCategory"]);
        close();
      }
    },
    onError: error => {
        showAlert({
            title: "Error",
            msg: "An internal error occurred. Please try again.",
            icon: "error",
            button: "Try again",
          });
    }
  });

  const {mutate:saveMenu, isLoading:savingMenu} = useMutation({
    mutationKey:["saveMenuItem"],
    mutationFn: createMenuItem,
    onError: error => {
      showAlert({
        title: "Error",
        msg: "An internal error occurred. Please try again.",
        icon: "error",
        button: "Try again",
      });
    },
    onSuccess: data => {
      // console.log(data)
      if (!data.status) {
        showAlert({
          title: "Error",
          msg: "Failed to create menu. Please try again.",
          icon: "error",
          button: "Try again",
        });
      } else {
        showAlert({
          title: "Success",
          msg: "Item created successfully.",
          icon: "success",
          button: "Done",
        });
        queryClient.invalidateQueries(["getItems"]);
        close();
      }
    }
  })
  const handleCategorySubmit = (e) => {
    e.preventDefault();
    saveMenuCategory({
      hotelId: adminDetails?.hotelId?._id,
      type: categoryType,
      name: categoryName,
    });
  };
  const handleMenuSubmit = (e) => {
    e.preventDefault();
    if ( !menuType || !menuItem || !itemCategory || !itemPrice ) {
      return  showAlert({
        title: "Error",
        msg: "Please fill in all fields.",
        icon: "error",
        button: "OK",
      });
    }
    saveMenu({
      hotelId: adminDetails?.hotelId?._id,
      type: menuType,
      name: menuItem,
      category:itemCategory,
      amount:itemPrice,
      description:itemDescription,
    });
  };

  const {data:allCategory} = useQuery({
    queryKey:["allCategory", adminDetails?.hotelId?._id, menuType ],
    queryFn: fetchMenuCategories,
    enabled: (!!menuType)
  })

  return (
    <div
      className="modal d-block"
      id="exampleModal"
      tabIndex={-1}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 120px)' }}>
          <div className="modal-header">
            <h5 className="modal-title">Manage Menu</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={close}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <ul className="nav nav-tabs text-center center-text">
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "menuItem" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("menuItem")}
                >
                  New Menu Item
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "category" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("category")}
                >
                  New Category
                </a>
              </li>
            </ul>
            <div className="tab-content">
              {activeTab === "menuItem" && (
                <div className="tab-pane fade show active">
                  <form onSubmit={handleMenuSubmit}>
                    <div className="row my-3">
                  <div className="form-group mb-3 col-lg-6">
                        <label htmlFor="menuType">Menu Type</label>
                        <select
                          value={menuType}
                          className="select form-control"
                          id="menuType"
                          onChange={(e) => setMenuType(e.target.value)}
                        >
                          <option selected disabled>
                            Choose
                          </option>
                          <option value={"food"}>Food</option>
                          <option value={"drinks"}>Drinks</option>
                          <option value={"pool"}>Pool Side</option>
                        </select>
                      </div>
                      {/* allCategory */}
                      <div className="form-group mb-3 col-lg-6">
                        <label htmlFor="itemCategory">Category</label>
                        <select
                          value={itemCategory}
                          className="select form-control"
                          id="itemCategory"
                          onChange={(e) => setItemCategory(e.target.value)}
                        >
                          <option selected disabled>
                            Choose
                          </option>
                          {allCategory?.value?.map((item, index)=><option key={`hgdg-${index}`} value={item?._id}>{makeTitleCase(item?.name)}</option>)}
                        </select>
                      </div>

                    <div className="form-group">
                      <label htmlFor="itemName">Item Name</label>
                      <input
                        type="text"
                        className="input form-control"
                        id="itemName"
                        placeholder="Enter item name"
                        value={menuItem}
                        onChange={(e)=>setMenuItem(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="itemPrice">Price</label>
                      <input
                        type="number"
                        className="form-control"
                        id="itemPrice"
                        placeholder="Enter price"
                        value={itemPrice}
                        onChange={(e)=>setItemPrice(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="itemDescription">Description <small>(optional)</small></label>
                        <textarea cols={3} rows={3} name="itemDescription" id="itemDescription" value={itemDescription} onChange={(e)=>setItemDescription(e.target.value)}></textarea>
                      </div>


                    <button type="submit" className={`btn  btn-lg my-3 ${savingMenu?"btn-secondary":"btn-primary"}`}
                    disabled={savingMenu}
                    >
                      {savingMenu?"Saving...":"Create Menu Item"}
                    </button>
                    </div> </form>
                </div>
              )}
              {activeTab === "category" && (
                <div className="tab-pane fade  show active">
                  <div className="py-3">
                    <form onSubmit={handleCategorySubmit}>
                      <div className="form-group mb-3">
                        <label htmlFor="categoryType">Menu Type</label>
                        <select
                          value={categoryType}
                          className="select form-control"
                          id="categoryType"
                          onChange={(e) => setCategoryType(e.target.value)}
                        >
                          <option selected disabled>
                            Choose
                          </option>
                          <option value={"food"}>Food</option>
                          <option value={"drinks"}>Drinks</option>
                          <option value={"pool"}>Pool Side</option>
                        </select>
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="categoryName">Category Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="categoryName"
                          placeholder="Enter a name for this category "
                          onChange={(e) => setCategoryName(e.target.value)}
                          value={categoryName}
                        />
                      </div>

                      <button type="submit" className={`btn mt-3 ${savingCategory?"btn-secondary":"btn-primary"}`} disabled={savingCategory}>
                        {savingCategory?"Saving...":"Add Category"}
                      </button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateModal;
