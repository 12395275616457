import React from 'react'
import WebsiteLayout from '../components/layouts/WebsiteLayout'
import BlogPostHeader from '../components/Blog/BlogPostHeader'
import {useParams} from "react-router-dom"
import { useEffect } from 'react'
import { getSingleBlogPost } from '../utils/blog'
import { useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import {Helmet} from "react-helmet";


const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p className="align-center">{children}</p>;

const BlogPost = () => {
let {title, id} = useParams()
const [post, setPost] = useState(null)
const getPost = async () => {
    let post = await getSingleBlogPost(id)   
    setPost(post)
}
useEffect(() => {
  getPost()
}, [])

const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <img
          className='img-responsive'
          src={`https://${node.data.target.fields.file.url}`}
          height={node.data.target.fields.file.details.image.height}
          width={node.data.target.fields.file.details.image.width}
          alt={node.data.target.fields.description}
        />
        )
      },
    },
  }

  return (<>
            <Helmet>
            <link rel="preload" href="https://cdn.shareaholic.net/assets/pub/shareaholic.js" as="script" />
<script data-cfasync="false" async src="https://cdn.shareaholic.net/assets/pub/shareaholic.js"></script>
            </Helmet>
    <WebsiteLayout>
        <BlogPostHeader post={post}/>

       <section className="section-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-12"> 
      {post && documentToReactComponents(post.fields.body, options)}
      </div>
    </div>
  </div>
</section>

      
    </WebsiteLayout>
  </>)
}

export default BlogPost
