import React from "react";
import { Link } from "react-router-dom";

function OurHotel() {
  return (
    <section className="rooms1 section-padding bg-cream" data-scroll-index={1}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-subtitle">Genesis Hotels</div>
            <div className="section-title">Our Hotels</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="item">
              <div className="position-re o-hidden">
                {" "}
                <img src={require("../assets/images/rooms/sojourner1.webp")} alt />
              </div>{" "}
              <span className="category">
                <a href="/hotel/the-sojourner-by-genesis">Book</a>
              </span>
              <div className="con">
                <h6>
                  <a href="/hotel/the-sojourner-by-genesis">Lagos - 94 Rooms</a>
                </h6>
                <h5>
                  <a href="/hotel/the-sojourner-by-genesis">The Sojourner by Genesis</a>{" "}
                </h5>
                <div className="line" />
                <div className="row facilities">
                  <div className="col col-md-7">
                    <ul>
                      <li>
                        <i className="flaticon-phone" />

                      </li>
                    </ul>
                  </div>
                  <div className="col col-md-5 text-right">
                    <div className="permalink">
                      <a href="/hotel/the-sojourner-by-genesis">
                        Details <i className="ti-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="item">
              <div className="position-re o-hidden">
                {" "}
                <img src={require("../assets/images/rooms/reventon1.webp")} alt />
              </div>{" "}
              <span className="category">
                <a href="/hotel/genesis-reventon-park">Book</a>
              </span>
              <div className="con">
                <h6>
                  <a href="/hotel/genesis-reventon-park">Port Harcourt - 64 Rooms</a>
                </h6>
                <h5>
                  <a href="/hotel/genesis-reventon-park">Genesis Reventon Park</a>
                </h5>
                <div className="line" />
                <div className="row facilities">
                  <div className="col col-md-7">
                    <ul>
                      <li>
                        <i className="flaticon-phone" />
                      </li>
                     
                    </ul>
                  </div>
                  <div className="col col-md-5 text-right">
                    <div className="permalink">
                      <a href="/hotel/genesis-reventon-park">
                        Details <i className="ti-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="item">
              <div className="position-re o-hidden">
                {" "}
                <img src={require("../assets/images/rooms/wellnesspark1.webp")} alt />
              </div>{" "}
              <span className="category">
                <a href="/hotel/genesis-wellness-park">Book</a>
              </span>
              <div className="con">
                <h6>
                  <a href="/hotel/genesis-wellness-park">Port Harcourt - 103 Rooms</a>
                </h6>
                <h5>
                  <a href="/hotel/genesis-wellness-park">Genesis Wellness Park</a>
                </h5>
                <div className="line" />
                <div className="row facilities">
                  <div className="col col-md-7">
                    <ul>
                      <li>
                        <i className="flaticon-phone" />
                      </li>
                    
                    </ul>
                  </div>
                  <div className="col col-md-5 text-right">
                    <div className="permalink">
                      <a href="/hotel/genesis-wellness-park">
                        Details <i className="ti-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="item">
              <div className="position-re o-hidden">
                {" "}
                <img src={require("../assets/images/rooms/place1.webp")} alt />
              </div>{" "}
              <span className="category">
                <a href="/hotel/genesis-place">Book</a>
              </span>
              <div className="con">
                <h6>
                  <a href="/hotel/genesis-place">Port Harcourt - 38 Rooms</a>
                </h6>
                <h5>
                  <a href="/hotel/genesis-place">Genesis Place</a>
                </h5>
                <div className="line" />
                <div className="row facilities">
                  <div className="col col-md-7">
                    <ul>
                      <li>
                        <i className="flaticon-phone" />
                        09087250217 
                      </li>
                     
                    </ul>
                  </div>
                  <div className="col col-md-5 text-right">
                    <div className="permalink">
                      <a href="/hotel/genesis-place">
                        Details <i className="ti-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="item">
              <div className="position-re o-hidden">
                {" "}
                <img src={require("../assets/images/rooms/castle1.webp")} alt />
              </div>{" "}
              <span className="category">
                <a href="/hotel/genesis-castle">Book</a>
              </span>
              <div className="con">
                <h6>
                  <a href="/hotel/genesis-castle">Port Harcourt - 34 Rooms</a>
                </h6>
                <h5>
                  <a href="/hotel/genesis-castle">Genesis Castle</a>
                </h5>
                <div className="line" />
                <div className="row facilities">
                  <div className="col col-md-7">
                    <ul>
                      <li>
                        <i className="flaticon-phone" />
                        08188202446
                      </li>
                      
                    </ul>
                  </div>
                  <div className="col col-md-5 text-right">
                    <div className="permalink">
                      <a href="/hotel/genesis-castle">
                        Details <i className="ti-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="item">
              <div className="position-re o-hidden">
                {" "}
                <img src={require("../assets/images/rooms/jerseyxx.webp")} alt />
              </div>{" "}
              <span className="category">
                <a href="/hotel/genesis-jersey-estate">Book</a>
              </span>
              <div className="con">
                <h6>
                  <a href="/hotel/genesis-jersey-estate">Port Harcourt - 34 Rooms</a>
                </h6>
                <h5>
                  <a href="/hotel/genesis-jersey-estate">Jersey Apartments</a>
                </h5>
                <div className="line" />
                <div className="row facilities">
                  <div className="col col-md-7">
                    <ul>
                      <li>
                        <i className="flaticon-phone" />
                      </li>
                    
                    </ul>
                  </div>
                  <div className="col col-md-5 text-right">
                    <div className="permalink">
                      <a href="/hotel/genesis-jersey-estate">
                        Details <i className="ti-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="item">
              <div className="position-re o-hidden">
                {" "}
                <img src={require("../assets/images/rooms/cottage1.webp")} alt />
              </div>{" "}
              <span className="category">
                <a href="/hotel/genesis-cottage">Book</a>
              </span>
              <div className="con">
                <h6>
                  <a href="/hotel/genesis-cottage">Port Harcourt - 7 Rooms</a>
                </h6>
                <h5>
                  <a href="/hotel/genesis-cottage">Genesis Cottage</a>
                </h5>
                <div className="line" />
                <div className="row facilities">
                  <div className="col col-md-7">
                    <ul>
                      <li>
                        <i className="flaticon-phone" />
                        09087250217
                      </li>
                    </ul>
                  </div>
                  <div className="col col-md-5 text-right">
                    <div className="permalink">
                      <a href="/hotel/genesis-cottage">
                        Details <i className="ti-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="item">
              <div className="position-re o-hidden">
                <img src={require("../assets/images/logos/genesis_30.png")} alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurHotel;
