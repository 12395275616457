import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearSearch, setMenuCategory, setMenuSearch, setMenuType } from '../../store/slices/menuSlice';
import { useQuery } from '@tanstack/react-query';
import { fetchMenuCategories } from '../../api/menu.api';
import { makeTitleCase } from '../../utils/helpers';
import { FaRedo } from 'react-icons/fa';

const MenuFilter = () => {
  const dispatch = useDispatch();
  const {menuType, menuCategory, menuSearch} = useSelector(state=>state.menu)
  const handleMenuTypeChange = (e) => {
    e.preventDefault();
    dispatch(setMenuType(e.target.value))
  }
  const handleCategoryChange = (e) => {
    e.preventDefault();
    dispatch(setMenuCategory(e.target.value))
  }
  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(setMenuSearch(e.target.value))
  }
  let { adminDetails } = useSelector((state) => state.admin);

  const {data:allCategory} = useQuery({
    queryKey:["allCategory", adminDetails?.hotelId?._id, menuType ],
    queryFn: fetchMenuCategories,
    enabled: (!!menuType)
  })
  return (
    <div className="card mb-4">
    <div className="card-header d-flex justify-content-between">
      <h5 className="m-0">Filter Menu Items</h5>
      <button className='btn btn-sm btn-primary' onClick={()=> dispatch(clearSearch())}><FaRedo className=''/> <span>Reset</span></button>
    </div>
    <div className="card-body">
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="typeSelect">Type</label>
            <select value={menuType} className="form-control" id="typeSelect" onChange={handleMenuTypeChange}>
              <option value="food">Food</option>
              <option value="drinks">Drinks</option>
              <option value="pool">Pool Side</option>
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="categorySelect">Category</label>
            <select className="form-control" id="categorySelect" value={menuCategory || ""} onChange={handleCategoryChange}>
              <option value={""} selected disabled>Select Category</option>
              {allCategory?.value?.map((item, index)=><option key={`yehdnb-${index}`} value={item?._id}>{makeTitleCase(item?.name)}</option>)}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="searchInput">Search</label>
            <input type="text" className="form-control" id="searchInput" placeholder="Search by name or amount" value={menuSearch} onChange={handleSearch}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default MenuFilter
