import React from "react";

function Facilities() {
  return (
    <section className="facilties section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-subtitle">Our Services</div>
            <div className="section-title">Hotel Facilities</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div
              className="single-facility "
            >
              <span className="flaticon-world" />
              <h5>Close Proximity</h5>
              <p>
                Our hotels are within close proximity from the airports and in town.
              </p>
              <div className="facility-shape">
                {" "}
                <span className="flaticon-world" />{" "}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="single-facility"
            >
              <span className="flaticon-car" />
              <h5>Parking Space</h5>
              <p>
                We have very spacious and secure parking lots in every of our hotels, so you don't have to worry about your car
              </p>
              <div className="facility-shape">
                {" "}
                <span className="flaticon-car" />{" "}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="single-facility"
            >
              <span className="flaticon-bed" />
              <h5>Room Service</h5>
              <p>
                24 hours access to quality room service, always available at your call.
              </p>
              <div className="facility-shape">
                {" "}
                <span className="flaticon-bed" />{" "}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="single-facility"
            >
              <span className="flaticon-swimming" />
              <h5>Swimming Pool</h5>
              <p>
                Our hotels have very comfortable swimming pools to help you relax when you need to.
              </p>
              <div className="facility-shape">
                {" "}
                <span className="flaticon-swimming" />{" "}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="single-facility"
            >
              <span className="flaticon-wifi" />
              <h5>Fibre Internet</h5>
              <p>
                Super fast internet available every time of the day. Allowing you stream your favourite videos when you want.
              </p>
              <div className="facility-shape">
                {" "}
                <span className="flaticon-wifi" />{" "}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="single-facility"
            >
              <span className="flaticon-breakfast" />
              <h5>Breakfast</h5>
              <p>
                Eat complimentary breakfast when you stay in any of our hotels.
              </p>
              <div className="facility-shape">
                {" "}
                <span className="flaticon-breakfast" />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Facilities;
