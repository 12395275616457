import React from 'react'
import Header from '../components/Hotel/Header'
import WebsiteLayout from '../components/layouts/WebsiteLayout'
import Navbar from '../components/Navbar'
import OurHotel from '../components/OurHotel'
import Services from '../components/Services'

const image = require("../assets/images/banners/sojourn3.webp")

function Hotels() {
  return (
    <WebsiteLayout>
        <Header image={image} title="Experience Luxury" subtitle="Genesis Hotels" />
        <OurHotel/>
        <Services/>
    </WebsiteLayout>
  )
}

export default Hotels