import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import {
  fetchHotels,
  fetchRoomsByHotel,
  fetchSingleHotelBySlug,
  fetchAvailableRoomsByHotel,
} from "../api/hotels.api";
import Header from "../components/Hotel/Header";
import WebsiteLayout from "../components/layouts/WebsiteLayout";
import BookingModal from "../components/Modals/BookingModal";
import Navbar from "../components/Navbar";
import SingleRoomTile from "../components/SingleRoomTile";
import hotels from "../hotels.json";
import { showAlert } from "../utils/helpers";
import JerseyWebsiteLayout from "../components/layouts/JerseyLayout";
import SingleHotelLayout from "../components/layouts/SingleHotelLayout";
function SingleHotel() {
  const { slug } = useParams();
  // console.log(slug)
  const [searchParams, setSearchParams] = useSearchParams();
  let ref = searchParams.get("ref");
  let arrival = searchParams.get("arrival");
  let departure = searchParams.get("departure");
  let adult = searchParams.get("adult");
  let children = searchParams.get("children");
  const [hotel, setHotel] = useState(null);
  const [rooms, setRooms] = useState(null);
  const [avRooms, setAvRooms] = useState(null);
  const [availableRooms, setAvailableRooms] = useState(null);

  const { isLoading: loadingHotel } = useQuery(
    ["hotels", slug],
    fetchSingleHotelBySlug,
    {
      onSuccess: (data) => {
        // console.log(data)
        setHotel(data.value);
      },
      onError: (err) => {
        console.log(err);
        return showAlert({
          title:"Error",
          msg:"An internal error occured, Try again.",
          icon:"error",
        })
      },
    }
  );

  const {
    data,
    isLoading: loadingRooms,
    refetch: getRooms,
  } = useQuery(["rooms", hotel?._id], fetchRoomsByHotel, {
    enabled: false,
    onSuccess: (data) => {
      // console.log(data)
      setRooms(data.value);
      setAvailableRooms(data.value);
    },
    onError: (err) => {
      console.log(err);
      return showAlert({
        title:"Error",
        msg:"An internal error occured, Try again.",
        icon:"error",
      })
    },
  });

  const { isLoading: loadingAvailableRooms, refetch: getAvailableRooms } =
    useQuery(
      [
        "availableRooms",
        {
          property: hotel?.bookingId,
          arrival,
          departure,
          adult,
          children,
        },
      ],
      fetchAvailableRoomsByHotel,
      {
        enabled: false,
        onSuccess: (data) => {
          // console.log(data)
          if (!data.success) return;
          setAvRooms(data.result);
          // setRooms(data.value);
        },
        onError: (err) => {
          console.log(err);
          // return showAlert({
          //   title:"Error",
          //   msg:"An internal error occured, Try again.",
          //   icon:"error",
          // })
        },
      }
    );

  // const hotel = hotels.find((hx) => hx.id === id);

  useEffect(() => {
    if (hotel) getRooms();
  }, [hotel]);

  useEffect(() => {
    if (ref && hotel && ref === "booking") getAvailableRooms();
  }, [ref, hotel]);

  useEffect(() => {
    if (avRooms && avRooms.length > 0) {
      let available = rooms.filter((room) =>
        avRooms.find((avRoom) => avRoom.roomId == room.roomId)
      );
      setAvailableRooms(available);
    }
  }, [avRooms, rooms]);

  return (
     <SingleHotelLayout hotel={hotel}>
      <Navbar />
      {!hotel && (
        <Header
          image={""}
          title={""}
          subtitle={""}
          rating={0}
        />
      )}
      {hotel && (
        <Header
          image={hotel.coverImage}
          title={hotel.name}
          subtitle={`${hotel.address} - ${hotel.phone} `}
          rating={hotel.rating}
        />
      )}

      <section class="section-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              {
                !availableRooms && loadingRooms && 
                <div style={{textAlign:"center"}}>
                  <Spinner animation="border" size="lg"/>
                </div>
              }
              {availableRooms &&
                availableRooms.map((room, index) =>
                  ref ? (
                    <SingleRoomTile hotel={hotel} room={room} index={index} params={{
                      ref, arrival, departure, adult, children
                    }} />
                  ) : (
                    <SingleRoomTile hotel={hotel} room={room} index={index} />
                  )
                )}
            </div>
          </div>
        </div>
      </section>
     
    </SingleHotelLayout>
  );
}

export default SingleHotel;
